import { Flex, Box, Text, Select } from '@chakra-ui/react';
import { ReactComponent as PenIconGreen } from 'assets/pen-icon-green.svg';
import { CreateAccount, Signer } from '../../types/account';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_SM } from 'utils/constants';

interface OwnProps {
  data: CreateAccount;
  setData: (data: CreateAccount) => void;
}

const SetThresholdForm = ({ data, setData }: OwnProps) => {
  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  return (
    <Flex
      w="full"
      direction="column"
      gap={6}
      bg={!isScreenSmall ? "white" : ""}
      boxShadow={!isScreenSmall ? "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)" : ""}
      borderRadius={16}
      pb={isScreenSmall ? 0 : "54px"}
    >
      <Flex
        p={!isScreenSmall ? 54 : "24px"}
        pb={0}
        direction="column"
        gap={2}
      >
        <Text
          position="relative"
          display="flex"
          pl={isScreenSmall ? "0" : "80px"}
          size="lg"
          variant="bodyBold"
        >
          <Box
            as="span"
            position="absolute"
            top={0}
            left={0}
            display={isScreenSmall ? "none" : "block"}
          >
            <PenIconGreen />
          </Box>
          Set Threshold
        </Text>
        <Text
          pl={isScreenSmall ? "0" : "80px"}
          size="sm"
          color="blackAlpha.600"
        >
          Signing any transaction requires the confirmation of:
        </Text>
      </Flex>

      <Flex
        flex={1}
        gap={4}
        px={isScreenSmall ? '24px' : 54}
      >
        <Flex
          flex={1}
          bg="primary"
          borderRadius={16}
          alignItems="center"
          justifyContent="center"
        >
          <Select
            mr={4}
            w={74}
            borderRadius={16}
            onChange={e => {
              setData({
                ...data,
                threshold: Number(e.target.selectedOptions[0].value) + 1,
              });
            }}
          >
            {data.accounts &&
              data?.accounts?.map((signer: Signer, index: number) => {
                return (
                  <option key={index} value={index}>
                    {index + 1}
                  </option>
                );
              })}
          </Select>
          <Text size="lg" variant="bodyBold">{`out of ${data?.accounts?.length} Signers`}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SetThresholdForm;
