import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_MD } from 'utils/constants';
import { ReactComponent as ArrowDownBlack } from 'assets/arrow-down-black-icon.svg';

export const styles = {
  ModalBackBtn: {
    cursor: 'pointer',
    transform: "rotate(90deg)",
    color: "#565656",
    marginTop: -3
  }
};


interface OwnProps {
  data: {
    open: boolean;
    address: string;
    amount: string;
    isLoading: boolean;
    create: () => void;
    handleClose: () => void;
    handleBack?: () => void;
  };
}

const ConfirmTransactionModal = ({ data }: OwnProps) => {
  const { handleClose, handleBack, address, amount, open, create, isLoading } = data;

  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });

  return (
    <Modal onClose={handleClose} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="586px"
        w="full"
        mx={isScreenMedium ? 4 : 0}
      >
        <ModalHeader pt={6} px={6} pb={0}>
          <ArrowDownBlack
            style={styles.ModalBackBtn}
            onClick={() => {
              if (handleBack) {
                handleBack();
              } else {
                handleClose()
              }
            }}
          />
          <Flex alignItems="center" flexDirection="column">
            <Text mt={10} size="2xl" variant="bodyBold">
              Confirm Transaction
            </Text>
          </Flex>
          <ModalCloseButton
            mt={2}
            mr={2}
            color="#565656"
            _hover={{ border: 'none' }}
            _focusVisible={{ outline: 'none' }}
          />
        </ModalHeader>
        <ModalBody pb={14} pt={4} px={8} w="full" maxW={{ base: '420px', md: '520px' }} mx="auto">
          <Flex alignItems="center" flexDirection="column" gap={4} flex={1}>
            <Box width="full">
              <Text mb={2} size="md" color="secondary">
                Recipient address
              </Text>
              <Text variant="bodyBold">{address}</Text>
            </Box>
            <Box width="full">
              <Text mb={4} size="md" color="secondary">
                Amount
              </Text>
              <Text variant="bodyBold">{amount} HBAR</Text>
            </Box>
              <Button
              w={204}
              variant="primary"
              borderRadius={6}
              onClick={create}
              isLoading={isLoading}
            >
              Create
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmTransactionModal;
