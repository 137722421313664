import { getAuthAxiosInstance as authAxios } from './axios';
import { CreateAccount, ImportAccount } from '../types/account';
import { CreateTransaction, TxInfo } from '../types/transactions';
import { REACT_APP_BACKEND_URL } from '../utils/constants';
import { CreateTokenType } from 'types/token';

export const createMultisigAccount = async (accountData: CreateAccount) => {
  return authAxios()?.post(`${REACT_APP_BACKEND_URL}/wallet`, accountData);
};

export const getMultisigAccountData = async (accountId: string) => {
  return authAxios()?.get(`${REACT_APP_BACKEND_URL}/wallet/${accountId}`);
};

export const importMultisigAccount = async (accountData: ImportAccount) => {
  return authAxios()?.post(`${REACT_APP_BACKEND_URL}/wallet/import`, accountData);
};

export const createContractTransaction = async (
  walletId: string,
  transactionData: CreateTransaction,
) => {
  return authAxios()?.post(`${REACT_APP_BACKEND_URL}/wallet/${walletId}/interaction`, transactionData);
};

export const getMultisigAccounts = async () => {
  return authAxios()?.get(`${REACT_APP_BACKEND_URL}/wallet`);
};

export const createSendTransaction = async (
  tx: TxInfo,
  sendTokenResponse: {txId: string, scheduleId: string},
  walletId: string,
  description?: string,
) => {
  const { to, amount } = tx;
  return authAxios()?.post(`${REACT_APP_BACKEND_URL}/wallet/${walletId}/transaction`,
    {
      amount,
      description: description ?? 'description',
      toAccountId: to,
      transactionId: sendTokenResponse.txId.toString(),
      scheduleId: sendTokenResponse.scheduleId.toString()
    });
};

export const getWalletTransactions = async (walletId: string) => {
  return authAxios()?.get(`${REACT_APP_BACKEND_URL}/wallet/${walletId}/transactions`);
};

export const signCheck = async (
  walletId: string,
  signedTransactionId: string,
  transactionId: string,
  accountId: string,
) => {
  return authAxios()?.post(
    `${REACT_APP_BACKEND_URL}/wallet/${walletId}/transaction/${transactionId}/signature`,
    {
      accountId,
      transactionId: signedTransactionId,
    });
};

export const createToken = async (
  walletId: string,
  tokenData: CreateTokenType,
) => {
  return authAxios()?.post(`${REACT_APP_BACKEND_URL}/wallet/${walletId}/create-token`, tokenData);
};
