import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { PublicKey } from '@hashgraph/sdk';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createMultisigAccount } from '../../api/multisigAPI';
import { getAccountData } from '../../api/userAPI';
import { useToast } from '../../hooks/useToast';
import { GlobalContext } from '../../providers/Global';
import { CreateAccount, Signer } from '../../types/account';
import { MultisigRoles, SCREEN_LG, SCREEN_SM } from '../../utils/constants';
import { ApplicationRoutes } from '../../utils/routes';
import AccountForm from './AccountForm';
import Menu from './components/Menu';
import SetThresholdForm from './SetThresholdForm';
import SignersForm from './SignersForm';
import SummaryForm from './SummaryForm';
import { useMediaQuery } from 'react-responsive';
import { ReactPortal } from 'hooks/usePortal';

const styles = {
  steps: (isMobile: boolean) => {
    if (isMobile) {
      return {
        position: "absolute",
        top: '24px',
        right: '24px',
        fontSize: '10px',
        color: 'rgba(0, 0, 0, 0.48)',
      }
    }
    return {
      margin: "auto",
    }
  }
};

export const getSignerAccount = (
  name: string = '',
  accountId: string = '',
  accountKey: string = ''
): Signer => {
  return {
    name,
    accountId,
    accountKey,
  }
}

const CreateAccountPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [nextButtonRoute, setNextButtonRoute] = useState(ApplicationRoutes.CreateAccount);
  const [backButtonRoute, setBackButtonRoute] = useState(ApplicationRoutes.SignersForm);
  const [missingSignersData, setMissingSignersData] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const {
    connection: { accountId, hashconnectConnectorInstance },
    connectedNetwork
  } = useContext(GlobalContext);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });
  const isScreenLarge = useMediaQuery({ query: `(max-width: ${SCREEN_LG})` });

  const creatorAccount = useMemo(() => getSignerAccount(MultisigRoles.Creator, accountId), [accountId]);

  const [createAccountData, setCreateAccountData] = useState<CreateAccount>({
    name: '',
    network: connectedNetwork,
    accounts: [
      creatorAccount,
      getSignerAccount(),
    ],
    threshold: 1,
    transactionId: '',
  });

  const updateRouteData = useCallback(() => {
    switch (location.pathname) {
      case ApplicationRoutes.CreateAccount:
        setStep(1);
        setNextButtonRoute(ApplicationRoutes.SignersForm);
        break;

      case ApplicationRoutes.SignersForm:
        setStep(2);
        setBackButtonRoute(ApplicationRoutes.CreateAccount);
        setNextButtonRoute(ApplicationRoutes.ThresholdForm);
        break;

      case ApplicationRoutes.ThresholdForm:
        setStep(3);
        setBackButtonRoute(ApplicationRoutes.SignersForm);
        setNextButtonRoute(ApplicationRoutes.SummaryForm);
        break;

      case ApplicationRoutes.SummaryForm:
        setStep(4);
        setBackButtonRoute(ApplicationRoutes.ThresholdForm);
        break;

      default:
        return null;
    }
  }, [location.pathname]);

  const renderComponent = () => {
    switch (location.pathname) {
      case ApplicationRoutes.CreateAccount:
        return <AccountForm data={createAccountData} setData={setCreateAccountData} />;

      case ApplicationRoutes.SignersForm:
        return (
          <SignersForm
            data={createAccountData}
            setData={setCreateAccountData}
            setIsInvalid={setIsInvalid}
          />
        );

      case ApplicationRoutes.ThresholdForm:
        return <SetThresholdForm data={createAccountData} setData={setCreateAccountData} />;

      case ApplicationRoutes.SummaryForm:
        return <SummaryForm data={createAccountData} duplicateAddress={isDuplicate} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setIsInvalid(false);
    if (!createAccountData.name) {
      setIsInvalid(true);
      return;
    }
    createAccountData?.accounts?.forEach((account: Signer) => {
      if (!account.name || !account.accountId) {
        setMissingSignersData(true);
        setIsInvalid(true);
        return;
      } else {
        setMissingSignersData(false);
      }
    });
  }, [createAccountData]);

  useEffect(() => {
    if (createAccountData?.accounts && createAccountData?.accounts?.length > 0) {
      const usedAddresses = createAccountData?.accounts?.map(account => account.accountId);
      const isDuplicate = usedAddresses?.some(function (item, idx) {
        return usedAddresses.indexOf(item) !== idx;
      });
      if (isDuplicate) {
        setIsDuplicate(true);
        return;
      }
      setIsDuplicate(false);
    }
  }, [createAccountData]);

  const mapAccountsData = async (): Promise<any> => {
    try {
      const mappedAccounts = createAccountData?.accounts?.map(async (account: Signer) => {
        return {
          ...account,
          accountKey: `302a300506032b6570032100${await (
            await getAccountData(account.accountId, connectedNetwork!)
          ).key}`,
        };
      });
      if (mappedAccounts) {
        return Promise.all(mappedAccounts);
      }
    } catch (e) {
      showToast('Invalid signer address', 'error', undefined, 'top-right');
    }
  };

  const createAccount = async () => {
    try {
      const mappedAccounts = await mapAccountsData();

      const transactionId = await hashconnectConnectorInstance.createMultisigAccount(
        mappedAccounts?.map((account: Signer) => PublicKey.fromString(account.accountKey)),
        createAccountData.threshold as number,
        accountId,
        connectedNetwork!,
        createAccountData.name,
      );

      setIsLoading(true);
      await createMultisigAccount({
        ...createAccountData,
        accounts: mappedAccounts,
        transactionId,
      });
      navigate(ApplicationRoutes.Base);
      showToast('Successfully created', 'success', undefined, 'top-right');
    } catch (err: unknown) {
      const error = err as AxiosError;
      showToast(error.message, 'error', undefined, 'top-right');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateRouteData();
  }, [location.pathname, updateRouteData]);

  useEffect(() => {
    if (!accountId) {
      navigate(ApplicationRoutes.Base);
    }
  }, [accountId, navigate]);

  useEffect(() => {
    if (location.pathname === ApplicationRoutes.CreateAccount) {
      setIsInvalid(false);
      setIsNextButtonDisabled(!createAccountData.name ? true : false);
    } else if (location.pathname === ApplicationRoutes.SignersForm) {
      if (createAccountData.accounts?.includes(creatorAccount) && createAccountData.accounts.length < 2) {
        setIsNextButtonDisabled(false)
        return
      }
      setIsNextButtonDisabled(missingSignersData ? true : false);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [location.pathname, createAccountData, missingSignersData, creatorAccount]);


  return (
    <ReactPortal
      id="extras"
      withHeader
      disablePortal={!isScreenSmall}
    >
      <Flex
        h="full"
        flexDirection="column"
        pt={isScreenSmall ? 0 : 87}
        maxW={1098}
        w="full"
        alignItems="stretch"
      >
        <Flex flex={1} justifyContent="center">
          {
            !isScreenLarge && (
              <Box mr={4}>
                <Menu />
              </Box>
            )
          }
          {renderComponent()}
        </Flex>
        <Flex
          display="flex"
          justifyContent="center"
          px={isScreenSmall ? 4 : 16}
          py={isScreenSmall ? 4 : 12}
          gap={4}
        >
          {
            location.pathname === ApplicationRoutes.CreateAccount
              ? (
                <Button
                  maxW={204}
                  w="full"
                  variant="primary"
                  isLoading={isLoading}
                  borderRadius={6}
                  onClick={() => navigate(ApplicationRoutes.Base)}
                >
                  Cancel
                </Button>
              )
              : (
                <Button
                  maxW={204}
                  w="full"
                  variant="primary"
                  borderRadius={6}
                  isLoading={isLoading}
                  onClick={() => navigate(backButtonRoute)}
                >
                  Back
                </Button>
              )
          }


          <Text
            sx={styles.steps(isScreenSmall)}
            whiteSpace="nowrap"
          >
            Step {step} of 4
          </Text>

          {
            location.pathname !== ApplicationRoutes.SummaryForm
              ? (
                <Button
                  maxW={204}
                  w="full"
                  variant="primary"
                  borderRadius={6}
                  onClick={() => navigate(nextButtonRoute)}
                  isDisabled={isNextButtonDisabled || isInvalid}
                >
                  Next
                </Button>
              )
              : (
                <Button
                  maxW={204}
                  w="full"
                  variant="primary"
                  borderRadius={6}
                  isLoading={isLoading}
                  isDisabled={isDuplicate || isInvalid}
                  onClick={createAccount}
                >
                  Create
                </Button>
              )
          }
        </Flex>
      </Flex>
    </ReactPortal>
  );
};

export default CreateAccountPage;
