import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as HashPackLogo } from 'assets/hashpack-logo.svg';
import { useContext } from 'react';
import { GlobalContext } from '../../providers/Global';
import { ModalType } from '../../utils/constants';
import { useToast } from 'hooks/useToast';

const SelectWalletNetworksModal = () => {
  const {
    modals: {
      [`${ModalType.ConnectWalletModal}`]: { open },
    },
    connection: { connectWallet, hashconnectConnectorInstance },
    closeModal,
  } = useContext(GlobalContext);
  const { showNoExtensionToast } = useToast();

  return (
    <Modal onClose={() => closeModal(ModalType.ConnectWalletModal)} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent minWidth="460px">
        <ModalHeader pt={6} px={6} pb={0}>
          <Flex alignItems="center">
            <Text w="100%" align="center" mt={6} size="2xl" variant="bodyBold">
              Connect Wallet
            </Text>
          </Flex>
          <ModalCloseButton color="#565656" mt={2} mr={2} />
        </ModalHeader>
        <ModalBody pt={6} pb={12} px={6}>
          <Flex
            h={76}
            px={6}
            py={4}
            cursor="pointer"
            borderRadius={16}
            onClick={() => {
              if (hashconnectConnectorInstance.extensionInstalled) {
                connectWallet();
                closeModal(ModalType.ConnectWalletModal);
              } else {
                showNoExtensionToast();
              }
            }}
            border="1px solid #E1E1E1"
          >
            <Box display="flex" alignItems="center">
              <HashPackLogo />
              <Text ml={4}>Hashpack</Text>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectWalletNetworksModal;
