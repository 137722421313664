import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Text, Flex, Link } from '@chakra-ui/react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../providers/Global';
import { SCREEN_LG, SCREEN_MD, SCREEN_SM } from '../../utils/constants';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import { ReactComponent as ExternalIcon } from 'assets/external-icon.svg';
import { ReactComponent as HederaLogo } from 'assets/hedera-logo.svg';
import TransactionList from './components/TransactionList';
import copyToClipboard from 'copy-to-clipboard';
import { useToast } from 'hooks/useToast';
import { useMediaQuery } from 'react-responsive';
import { MobileMenu } from './components/MobileMenu';
import { ApplicationRoutes } from 'utils/routes';

const AccountPage = () => {
  const { showToast } = useToast();
  const { currentAccount, connectedNetwork } = useContext(GlobalContext);
  const { walletId, multisigAccountId } = useParams();

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });
  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });
  const isScreenLarge = useMediaQuery({ query: `(max-width: ${SCREEN_LG})` });

  return (
    <Flex flex={1} flexDirection="column">
      <MobileMenu
        pageName='Dashboard'
        backUrl='/'
      />
      <Flex
        flex={1}
        flexDirection="column"
        gap={4}
      >
        <Flex
          gap={4}
          direction={isScreenMedium ? 'column' : 'row'}
        >
          <Box
            p={6}
            pt={isScreenSmall ? 4 : 6}
            flex={1}
            bg="white"
            borderRadius={16}
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
          >
            <Text
              size="lg"
              fontWeight={300}
              color="blackAlpha.500"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Account Details
              {
                isScreenLarge && (
                  <ChakraLink
                    as={RouterLink}
                    to={`${ApplicationRoutes.Dashboard}/${walletId}/signers/${multisigAccountId}`}
                    cursor="pointer"
                    fontSize="sm"
                    fontWeight="500"
                    letterSpacing="0.5px"
                    color="blackAlpha.900"
                    textDecoration="none !important"
                  >
                    View
                  </ChakraLink>
                )
              }
            </Text>
            <Box
              p={4}
              mt={isScreenSmall ? 2 : 6}
              mb={4}
              bg="primary"
              borderRadius={8}
            >
              <Text size="sm">Wallet address</Text>
              <Box display="flex" alignItems="center" gap={2} mt={2}>
                <Text size="lg" variant="bodyBold">
                  {multisigAccountId}
                </Text>
                <CopyIcon
                  cursor="pointer"
                  onClick={() => {
                    copyToClipboard(multisigAccountId || '');
                    showToast('Copied to clipboard', 'success', undefined, 'top');
                  }}
                />
                <Link
                  href={`https://hashscan.io/${connectedNetwork}/account/${multisigAccountId}`}
                  cursor="pointer"
                  isExternal
                >
                  <ExternalIcon />
                </Link>
              </Box>
            </Box>
            <Flex p={4} bg="primary" justifyContent="space-between" borderRadius={8}>
              <Box>
                <Text size="sm">Threshold</Text>
                <Text size="lg" variant="bodyBold">
                  {currentAccount?.threshold} out of {currentAccount?.keys?.length}
                </Text>
              </Box>
              <Box>
                <Text size="sm">Network</Text>
                <Text size="lg" variant="bodyBold" textTransform="capitalize">
                  {currentAccount?.network}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            p={6}
            pt={isScreenSmall ? 4 : 6}
            flex={1}
            bg="white"
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
            borderRadius={16}
          >
            <Text
              size="lg"
              fontWeight={300}
              color="blackAlpha.500"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Asset Balance
              {
                isScreenLarge && (
                  <ChakraLink
                    as={RouterLink}
                    to={`${ApplicationRoutes.Dashboard}/${walletId}/assets/${multisigAccountId}`}
                    cursor="pointer"
                    fontSize="sm"
                    fontWeight="500"
                    letterSpacing="0.5px"
                    color="blackAlpha.900"
                    textDecoration="none !important"
                  >
                    View
                  </ChakraLink>
                )
              }
            </Text>

            <Flex
              p={4}
              mt={isScreenSmall ? 2 : 6}
              bg="primary"
              borderRadius={8}
              justifyContent="space-between"
            >
              <Text size="xs">Asset</Text>
              <Text size="xs">Balance</Text>
            </Flex>
            <Flex p={4} justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={2}>
                <HederaLogo />
                <Text size="sm" variant="bodyBold" color="blackAlpha.600">
                  HBAR
                </Text>
              </Box>
              <Box>
                <Text size="sm" variant="bodyBold" color="blackAlpha.600">
                  {Number(currentAccount.balances?.hbars.replace(/[^0-9.]/g, '')).toFixed(2)}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <TransactionList />
      </Flex>
    </Flex>
  );
};

export default AccountPage;
