import {
  AccountCreateTransaction,
  AccountId,
  Client,
  Hbar,
  Key,
  KeyList,
  ScheduleCreateTransaction,
  ScheduleSignTransaction,
  Transaction,
  TransactionReceiptQuery,
  TransferTransaction,
} from '@hashgraph/sdk';
import { HashConnect, HashConnectTypes, MessageTypes } from 'hashconnect';
import { NetworkType } from '../utils/constants';
import { HashConnectSigner } from 'hashconnect/dist/esm/provider/signer';
import { TxInfo } from 'types/transactions';

class Hashconnect {
  hashconnect: HashConnect;

  appMetadata: HashConnectTypes.AppMetadata = {
    name: 'Hedera Multisig',
    description: 'Hedera Multisig',
    icon: 'https://absolute.url/to/icon.png',
  };

  topic: string = '';
  accountId: string = '';
  pairingString: string = '';
  pairingData: HashConnectTypes.SavedPairingData | null = null;
  extensionInstalled: boolean = false;

  setAccountId: (accountId: string) => void;
  setConnected: (loading: boolean) => void;

  constructor(setAccountId: (accountId: string) => void, setConnected: (loading: boolean) => void) {
    this.hashconnect = new HashConnect();
    this.setAccountId = setAccountId;
    this.setConnected = setConnected;
  }

  getSigner(network: NetworkType, accountToSign: string): HashConnectSigner {
    const provider = this.hashconnect.getProvider(network, this.topic, accountToSign);
    return this.hashconnect.getSigner(provider);
  }

  async initHashconnect(network: NetworkType) {
    this.hashconnect = new HashConnect();

    // register events
    this.setUpHashConnectEvents();
    this.foundExtension();

    let initData = await this.hashconnect.init(
      this.appMetadata,
      network,
      false,
    );

    this.topic = initData.topic;
    this.pairingString = initData.pairingString;
    this.pairingData = initData.savedPairings[0];

    if (this.pairingData && this.pairingData.accountIds[0]) {
      const accountId = localStorage.getItem('accountId') || this.pairingData.accountIds[0];
      this.setAccountId(accountId);
      this.accountId = accountId;
      this.setConnected(true);
    }
  }

  async createSendTokenScheduledTransaction(
    tx: TxInfo,
    network: NetworkType,
    signerAccountId: string
  ): Promise<{scheduleId: string, txId: string}> {
    const { from, to, amount } = tx;
    const signer = this.getSigner(network, signerAccountId);

    const transferTransaction = new TransferTransaction()
      .addHbarTransfer(from, new Hbar(-1 * amount))
      .addHbarTransfer(to, new Hbar(amount))

    const transaction = await new ScheduleCreateTransaction()
      .setScheduledTransaction(transferTransaction)
      .setPayerAccountId(AccountId.fromString(from))
      .freezeWithSigner(signer);

    const txResponse = await transaction.executeWithSigner(signer);
    const client = (network === 'testnet') ? Client.forTestnet() : Client.forMainnet();
    const receipt = await new TransactionReceiptQuery()
      .setTransactionId(txResponse.transactionId.toString() as string)
      .execute(client)

    return {
      scheduleId: receipt.scheduleId?.toString() as string,
      txId: receipt.scheduledTransactionId?.toString() as string,
    }
  };

  async createMultisigAccount(
    publicKeyList: any[],
    threshold: number,
    accountId: string,
    network: NetworkType,
    memo?: string,
  ) {
    const thresholdKey = new KeyList(publicKeyList, threshold);
    const multiSigAccount = await this.createAccount(thresholdKey, accountId, network, memo);
    return multiSigAccount;
  }

  async createAccount(publicKey: Key, accountId: string, network: NetworkType, memo?: string) {
    const signer = this.getSigner(network, accountId);
    const transaction = await new AccountCreateTransaction()
      .setKey(publicKey)
      .setAccountMemo(memo ?? '')
      .setInitialBalance(new Hbar(1))
      .freezeWithSigner(signer);

    const data: any = await transaction.executeWithSigner(signer);
    return data.transactionId;
  }

  setUpHashConnectEvents() {
    this.hashconnect.pairingEvent.on(data => {
      const accountId = localStorage.getItem('accountId') || data.accountIds[0];
      this.setAccountId(accountId);
      this.accountId = accountId;
    });
  }

  foundExtension() {
    this.hashconnect.foundExtensionEvent.once(walletMetadata => {
      if (walletMetadata) {
        this.extensionInstalled = true;
      }
    });
  }

  connectToExtension() {
    this.hashconnect.connectToLocalWallet();
  }

  clearPairings() {
    // this.pairingData = null;
    this.hashconnect.disconnect(this.topic);
    this.accountId = '';
  }

  async sendTransaction(
    trans: Uint8Array,
    acctToSign: string,
    return_trans: boolean = false,
    hideNfts: boolean = false,
  ) {
    const transaction: MessageTypes.Transaction = {
      topic: this.topic,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
        hideNft: hideNfts,
      },
    };

    return await this.hashconnect.sendTransaction(this.topic, transaction);
  }

  async signTransaction(trans: Transaction, signingAcctId: string) {
    const response = await this.sendTransaction(trans.toBytes(), signingAcctId, true);

    return Transaction.fromBytes(response.signedTransaction as Uint8Array);
  }

  // async createSendTokensTransaction(from: string, to: string, accountId: string) {
  //   const provider = this.hashconnect.getProvider('testnet', this.topic, accountId);
  //   const signer = this.hashconnect.getSigner(provider);

  //   let transaction = await new TransferTransaction()
  //     .addHbarTransfer(from, -1)
  //     .addHbarTransfer(to, 1)
  //     .freezeWithSigner(signer);

  //   return transaction;
  // }

  async signSendTokensTransaction(scheduleId: string, accountId: string, network: NetworkType) {
    const provider = this.hashconnect.getProvider(network, this.topic, accountId);
    const signer = this.hashconnect.getSigner(provider);

    const transaction = await new ScheduleSignTransaction()
      .setScheduleId(scheduleId)
      .freezeWithSigner(signer);

    const signedTx = await transaction.signWithSigner(signer);
    const signed = await signedTx.executeWithSigner(signer);

    return signed;
  }
}

export default Hashconnect;
