import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as ApprovedIcon } from 'assets/approved-icon.svg';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import copyToClipboard from 'copy-to-clipboard';

interface OwnProps {
  data: {
    isOpen: boolean;
    address: string;
    amount: number;
    handleClose: () => void;
  };
}

const SuccessModal = ({ data }: OwnProps) => {
  const { handleClose, isOpen } = data;

  return (
    <Modal onClose={handleClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent minWidth="594px">
        <ModalHeader pt={14} px={6} pb={0}>
          <Flex alignItems="center" flexDirection="column">
            <ApprovedIcon />
            <Text size="2xl" variant="bodyBold" mt={3} mb={6}>
              Success!
            </Text>
            <Text>Transaction submitted successfully</Text>
          </Flex>
          <ModalCloseButton
            mt={2}
            mr={2}
            color="#565656"
            _hover={{ border: 'none' }}
            _focusVisible={{ outline: 'none' }}
          />
        </ModalHeader>
        <ModalBody pb={14} pt={6} px={20}>
          <Flex alignItems="center" flexDirection="column" gap={4}>
            <Box display="flex" width="full" gap={4}>
              <Box>
                <Text mb={2} size="sm" color="secondary">
                  Connected address
                </Text>
                <Flex alignItems="center" gap={1}>
                  <Text>{data?.address}</Text>
                  <CopyIcon cursor="pointer" onClick={() => copyToClipboard(data?.address)} />
                </Flex>
              </Box>
              <Box>
                <Text mb={2} size="sm" color="secondary">
                  Recipient address
                </Text>
                <Flex alignItems="center" gap={1}>
                  <Text>{data?.address}</Text>
                  <CopyIcon cursor="pointer" onClick={() => copyToClipboard(data?.address)} />
                </Flex>
              </Box>
            </Box>
            <Box width="full">
              <Text mb={1} size="sm" color="secondary">
                Amount
              </Text>
              <Text>{data?.amount} HBAR</Text>
              <Divider mt={6} />
            </Box>
            <Box width="full" mt={2}>
              <Text mb={1} size="sm" color="secondary">
                Gas fee
              </Text>
              <Text>{data?.amount}</Text>
              <Divider mt={6} />
            </Box>
            <Box width="full" mt={2}>
              <Text mb={1} size="sm" color="secondary">
                Transaction
              </Text>
              <Flex alignItems="center" gap={1}>
                <Text>{data?.address}</Text>
                <CopyIcon cursor="pointer" onClick={() => copyToClipboard(data?.address)} />
              </Flex>
            </Box>
            <Button mt={2} w={240} variant="primary">
              Done
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
