import { NetworkName } from "@hashgraph/sdk/lib/client/Client";

export type { NetworkName as NetworkType };

export enum ModalType {
  FailedModal = 'FailedModal',
  SendTokensModal = 'SendTokensModal',
  CreateTokenModal = 'CreateTokenModal',
  ConnectWalletModal = 'ConnectWalletModal',
  CreateTransactionModal = 'CreateTransactionModal',
  ContractInteractionModal = 'ContractInteractionModal',
}

export enum SupportedWallets {
  Hashpack = 'Hashpack',
}

export enum SupportedNetworks {
  Previewnet = 'Previewnet',
  Testnet = 'Testnet',
  Mainnet = 'Mainnet',
}

export const hederaRestApiUrl = {
  mainnet: 'https://mainnet-public.mirrornode.hedera.com',
  testnet: 'https://testnet.mirrornode.hedera.com',
  previewnet: 'https://previewnet.mirrornode.hedera.com',
};

export enum MultisigRoles {
  Creator = 'MultiSig Creator',
  OwnAddress = 'My Own Address'
}

export const EXAMPLE_ABI = JSON.stringify([
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "message_",
        "type": "string"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "get_message",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "message_",
        "type": "string"
      }
    ],
    "name": "set_message",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
], null, 2)

export const REACT_APP_BACKEND_URL = 'https://hedera-multisig-backend-nezb3mf7xa-ew.a.run.app';

export const SCREEN_LG = '992px';
export const SCREEN_MD = '768px';
export const SCREEN_SM = '576px';
