export enum ApplicationRoutes {
  Base = '/',
  LoadAccount = '/load/account',
  CreateAccount = '/account/create',
  SignersForm = '/account/create/signers',
  ThresholdForm = '/account/create/threshold',
  SummaryForm = '/account/create/summary',
  Dashboard = '/dashboard',
  Account = ':walletId/account/:multisigAccountId',
  Assets = ':walletId/assets/:multisigAccountId',
  Transactions = ':walletId/transactions/:multisigAccountId',
  Signers = ':walletId/signers/:multisigAccountId',
  Settings = ':walletId/settings/:multisigAccountId',
}
