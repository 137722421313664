export enum TokenFields {
    name = 'name',
    symbol = 'symbol',
    decimals = 'decimals',
    initialSupply = 'initialSupply'
};

export type CreateTokenType = {
    [TokenFields.name]: String;
    [TokenFields.symbol]: String;
    [TokenFields.decimals]: Number;
    [TokenFields.initialSupply]: Number;
};
