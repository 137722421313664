import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { GlobalContext } from '../../providers/Global';
import { ModalType } from '../../utils/constants';

const FailedModal = () => {
  const {
    modals: {
      [`${ModalType.FailedModal}`]: { open },
    },
    closeModal,
  } = useContext(GlobalContext);

  return (
    <Modal onClose={() => closeModal(ModalType.FailedModal)} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent minWidth="460px">
        <ModalHeader pt={6} px={6} pb={0}>
          <Flex alignItems="center">
            <Text w="100%" align="center" mt={6} size="2xl" variant="bodyBold">
              Transaction Failed!
            </Text>
          </Flex>
          <ModalCloseButton color="#565656" mt={2} mr={2} />
        </ModalHeader>
        <ModalBody pt={6} pb={12} px={6}>
          <Flex alignItems="center" flexDirection="column">
            <Text mb={6}>Sending failed</Text>
            <Button w={240} variant="primary">
              Try again
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FailedModal;
