import { ComponentStyleConfig } from '@chakra-ui/react';

const Text: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
    variant: 'body',
  },
  baseStyle: {
    fontWeight: 400,
  },
  variants: {
    body: {
      fontFamily: 'body',
    },
    semiBold: {
      fontFamily: 'body',
      fontWeight: 500,
    },
    bodyBold: {
      fontFamily: 'body',
      fontWeight: 600,
    },
    extraBold: {
      fontFamily: 'body',
      fontWeight: 700,
    },
  },
  sizes: {
    '2xl': {
      fontSize: 24,
    },
    xl: {
      fontSize: 20,
    },
    lg: {
      fontSize: 18,
    },
    md: {
      fontSize: 16,
    },
    sm: {
      fontSize: 14,
    },
    xs: {
      fontSize: 12,
    },
  },
};

export default Text;
