import { SystemStyleObject } from "@chakra-ui/react";

export const contentBox: SystemStyleObject = {
    px: 12,
    py: 6,
    bg: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 16,
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
}
