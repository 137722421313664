import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { ReactComponent as GreenCircleIcon } from 'assets/green-circle.svg';
import { ReactComponent as GreenWalletIcon } from 'assets/green-wallet.svg';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../providers/Global';
import { ApplicationRoutes } from '../utils/routes';
import MultisigAccounts from './components/MultisigAccounts';
import { useToast } from 'hooks/useToast';
import { contentBox } from './styles/HomePage';

const HomePage = () => {
  const navigate = useNavigate();
  const { showNoExtensionToast } = useToast();

  const {
    connectedNetwork,
    connection: { accountId, connectWallet, hashconnectConnectorInstance },
  } = useContext(GlobalContext);

  const isConnectionNeeded = !connectedNetwork || !accountId;

  const handleBtnClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (accountId) {
      if (e.currentTarget.name === 'load') {
        navigate(ApplicationRoutes.LoadAccount);
        return;
      }
      if (e.currentTarget.name === 'create') {
        navigate(ApplicationRoutes.CreateAccount);
        return;
      }
    }
    if (hashconnectConnectorInstance.extensionInstalled) {
      connectWallet();
    } else {
      showNoExtensionToast();
    }
  }, [accountId, connectWallet, hashconnectConnectorInstance.extensionInstalled, navigate, showNoExtensionToast]);

  return (
    <Flex
      direction="column"
      maxW={1098}
      py={46}
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="hidden"
      >
        <Text
          fontSize={{ base: 32, md: 64, lg: 96 }}
          variant="bodyBold"
          align="center"
        >
          Welcome To
        </Text>
        <Text
          position="relative"
          mt={{ base: "-12px", lg: "-40px" }}
          fontSize={{ base: 32, md: 64, lg: 96 }}
          align="center"
          variant="bodyBold"
          zIndex={1}
          whiteSpace="nowrap"
        >
          Hedera MultiSig
          <Box
            as='span'
            position="absolute"
            h={{ base: 3, md: 6, lg: 8 }}
            zIndex={-1}
            bg="#B7F133"
            opacity="0.9"
            bottom={{ base: '3px', md: '8px', lg: '14px' }}
            insetX={{ base: '-1px', md: '0px', lg: '2px' }}
          />
        </Text>
      </Box>
      <Box mt={2} mb={{ base: 6, md: 16 }}>
        <Text
          size={{ base: '18px', md: '2xl' }}
          color="blackAlpha.500"
          fontWeight={300}
          align="center"
        >
          Manage your assets securely with a multi-signature account
        </Text>
      </Box>
      <Flex
        gap={4}
        mb={10}
        direction={{ base: 'column', md: 'row' }}
      >
        <Box sx={contentBox}>
          <Text size="lg" variant="bodyBold">
            Create MultiSig Account
          </Text>
          <Text mt={1} mb={4} color="blackAlpha.500" size="sm">
            Manage your assets securely with a multi-signature account
          </Text>
          <GreenCircleIcon style={{ margin: 'auto' }} />
          <Button
            name='create'
            mb={6}
            mt={10}
            w={188}
            variant="primary"
            borderRadius={6}
            onClick={handleBtnClick}
          >
            {isConnectionNeeded ? "Connect Wallet" : "Create"}
          </Button>
        </Box>
        <Box sx={contentBox}>
          <Text size="lg" variant="bodyBold">
            Load existing account
          </Text>
          <Text mt={1} mb={4} color="blackAlpha.500" size="sm">
            Already have a MultiSig? Please add your account address.
          </Text>
          <Box>
            <GreenWalletIcon style={{ margin: 'auto' }} />
            <Button
              name='load'
              w={205}
              mt={10}
              mb={6}
              variant="secondary"
              borderRadius={6}
              onClick={handleBtnClick}
            >
              {isConnectionNeeded ? "Connect Wallet" : "Add existing"}
            </Button>
          </Box>
        </Box>
      </Flex>
      {accountId && <MultisigAccounts />}
    </Flex>
  );
};

export default HomePage;
