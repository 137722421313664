import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Layout from './components/Layout';
import { GlobalProvider } from './providers/Global';
import { ApplicationRoutes } from './utils/routes';
import CreateAccountPage from './containers/CreateMultisig/CreateAccountPage';
import { Routes, Route } from 'react-router-dom';
import HomePage from './containers/HomePage';
import theme from './theme/index';
import Dashboard from './containers/Dashboard';
import TransactionsPage from './containers/Dashboard/TransactionsPage';
import AccountPage from './containers/Dashboard/AccountPage';
import AssetsPage from './containers/Dashboard/AssetsPage';
import SignersPage from './containers/Dashboard/SignersPage';
import SettingsPage from './containers/Dashboard/SettingsPage';
import LoadAccountPage from './containers/LoadAccountPage';

function App() {
  return (
    <GlobalProvider>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route element={<Layout />}>
            <Route path={ApplicationRoutes.Base} element={<HomePage />} />
            <Route path={ApplicationRoutes.LoadAccount} element={<LoadAccountPage />} />
            <Route path={`${ApplicationRoutes.CreateAccount}/*`} element={<CreateAccountPage />} />
            <Route path={ApplicationRoutes.Dashboard} element={<Dashboard />}>
              <Route path={ApplicationRoutes.Account} element={<AccountPage />} />
              <Route path={ApplicationRoutes.Assets} element={<AssetsPage />} />
              <Route path={ApplicationRoutes.Transactions} element={<TransactionsPage />} />
              <Route path={ApplicationRoutes.Signers} element={<SignersPage />} />
              <Route path={ApplicationRoutes.Settings} element={<SettingsPage />} />
            </Route>
          </Route>
        </Routes>
        <div id="extras" />
      </ChakraProvider>
    </GlobalProvider>
  );
}

export default App;
