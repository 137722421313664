import { Box, Flex, Text } from '@chakra-ui/react';
import { ApplicationRoutes } from '../../../utils/routes';
import { useLocation } from 'react-router-dom';

const menuItems = [
  {
    name: 'Personalise',
    route: ApplicationRoutes.CreateAccount,
  },
  {
    name: 'Signers',
    route: ApplicationRoutes.SignersForm,
  },
  {
    name: 'Set Threshold',
    route: ApplicationRoutes.ThresholdForm,
  },
  {
    name: 'Summary',
    route: ApplicationRoutes.SummaryForm,
  },
];

const Menu = () => {
  const location = useLocation();

  return (
    <Flex
      w={318}
      h="100%"
      p={14}
      gap={14}
      borderRadius={16}
      textAlign="center"
      direction="column"
      background="white"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      <Text size="lg" variant="bodyBold">
        Create Account
      </Text>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={6}>
        {menuItems.map(menuItem => {
          return (
            <Box key={menuItem.name}>
              <Text
                size="sm"
                variant="bodyBold"
                color={location.pathname === menuItem.route ? 'black' : 'blackAlpha.500'}
              >
                {menuItem.name}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Flex>
  );
};

export default Menu;
