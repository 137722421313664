import { Box, Flex, Spinner, Text, Image } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getMultisigAccounts } from '../../api/multisigAPI';
import { MultisigAccount } from '../../types/account';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationRoutes } from '../../utils/routes';
import { GlobalContext } from '../../providers/Global';
import HashBasedAvatar from 'components/Avatar';
import { getAvatarUID, toJDSvgUrl } from 'utils/avatar';
import { useMediaQuery } from 'react-responsive';

const MultisigAccounts = () => {
  const [accountsData, setAccountsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    connection: { accountId },
  } = useContext(GlobalContext);

  const fetchAccountData = useCallback(async () => {
    if (accountId && accountsData.length === 0) {
      setIsLoading(true);
      const result = await getMultisigAccounts();
      if (result?.data) {
        setAccountsData(result.data);
      }
      setIsLoading(false);
    }
  }, [accountId, accountsData.length]);

  useEffect(() => {
    window.addEventListener('token', fetchAccountData, false);
  }, [fetchAccountData]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  if (isLoading) {
    <Box textAlign="center" mt="120px">
      <Spinner size="lg" />
    </Box>
  }

  return (
    <Box
      p={3}
      bg="white"
      borderRadius={16}
      textAlign="center"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      <Text mb={6} px={3} size="lg" variant="bodyBold" textAlign={isTabletOrMobile ? "center" : "left"}>
        Connected Accounts
      </Text>

      <Flex flexDirection="column" maxH={accountsData.length >= 5 ? 80 * 5 : 'auto'} overflow="scroll">
      {
        accountsData.length > 0
          ? (
              accountsData.map((account: MultisigAccount) => {
              const { _id, name, account_id, keys, threshold, pending_transactions } = account;

              return (
                <ChakraLink
                  key={account.account_id}
                  as={RouterLink}
                  to={`${ApplicationRoutes.Dashboard}/${_id}/account/${account_id}`}
                  cursor="pointer"
                  position="relative"
                  display="flex"
                  p={4}
                  mx={3}
                  mb={4}
                  h={16}
                  bg="#F9F9F9"
                  borderRadius={16}
                  textDecoration="none !important"
                  alignItems="center"
                  columnGap={4}
                >
                  <Box display="flex" flex={1} alignItems="center">
                    {
                      isTabletOrMobile
                        ? (
                          <Text
                            as="span"
                            display="flex"
                            alignItems="center"
                            pl={1.5}
                            position="absolute"
                            top={2}
                            left={3.5}
                            fontSize="8px"
                            textTransform="uppercase"
                            letterSpacing="1px"
                            variant="extraBold"
                            color="blackAlpha.500"
                            whiteSpace="nowrap"
                          >
                            <Image
                              src={toJDSvgUrl(getAvatarUID(_id), 16)}
                              position="absolute"
                              top={1}
                              left={-2.5}
                              transform="translate(-50%, -50%)"
                              border="4px solid #F9F9F9"
                              borderRadius="16px 0 0 16px"
                            />
                            Signers
                            <Text
                              ml={1}
                              fontSize="8px"
                              textTransform="uppercase"
                              letterSpacing="1px"
                              variant="extraBold"
                              color="blackAlpha.900"
                            >
                              {`${threshold}/${keys?.length}`}
                            </Text>
                          </Text>
                        )
                        : (
                          <HashBasedAvatar UID={getAvatarUID(_id)} size={24} />
                        )
                    }
                    <Text
                      ml={isTabletOrMobile ? 1 : 4}
                      size="sm"
                      variant="bodyBold"
                      color="blackAlpha.500"
                      isTruncated
                    >
                      {name}
                    </Text>
                  </Box>
                  {
                    isTabletOrMobile
                      ? (
                        <Text size="sm" variant="bodyBold" isTruncated>
                          <Text
                            as="span"
                            position="absolute"
                            top={2}
                            right={4}
                            fontSize="8px"
                            textTransform="uppercase"
                            letterSpacing="1px"
                            variant="extraBold"
                            color="blackAlpha.600"
                          >
                            Waiting TX
                          </Text>
                          {pending_transactions}
                        </Text>
                      )
                      : (
                        <>
                          <Box display="flex" flex={1}>
                            <Text size="sm" variant="bodyBold">
                              {account_id}
                            </Text>
                          </Box>
                          <Box display="flex" flex={1} alignItems="center" overflow="hidden">
                            <Text mr={1} size="sm" variant="bodyBold" color="blackAlpha.500" whiteSpace="nowrap">
                              Signers
                            </Text>
                            <Text size="sm" variant="bodyBold" isTruncated>{`${threshold} out of ${keys?.length}`}</Text>
                          </Box>
                          <Box display="flex" flex={1} alignItems="center" overflow="hidden">
                            <Text mr={1} size="sm" variant="bodyBold" color="blackAlpha.500">
                              Waiting:
                            </Text>
                            <Text size="sm" variant="bodyBold" isTruncated>
                              {pending_transactions}
                              {pending_transactions === 1 ? ' Transaction' : ' Transactions'}
                            </Text>
                          </Box>
                        </>
                      )
                  }

                </ChakraLink>
              );
            })
          )
          : (
            <Flex flexDirection="column" justifyContent="center" minH={240}>
              <Text size="sm" color="blackAlpha.500" mt={-16}>
                See all your connected accounts here.

                <br />

                <ChakraLink
                  as={RouterLink}
                  to={ApplicationRoutes.CreateAccount}
                  textDecoration="underline"
                  variant="bodyBold"
                >
                  <strong>
                    Create
                  </strong>
                </ChakraLink>

                {' or '}

                <ChakraLink
                  as={RouterLink}
                  to={ApplicationRoutes.LoadAccount}
                  textDecoration="underline"
                >
                  <strong>
                    Add
                  </strong>
                </ChakraLink>

                {' account.'}
              </Text>
            </Flex>
          )
      }
      </Flex>
    </Box>
  );
};

export default MultisigAccounts;
