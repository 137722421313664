import { Box, Button, Divider, Flex, Text, Link } from '@chakra-ui/react';
import { ReactComponent as ArrowsIcon } from 'assets/arrows-icon.svg';
import { ReactComponent as ArrowsIconGray } from 'assets/arrows-icon-gray.svg';
import { ReactComponent as SquaresIcon } from 'assets/squares-icon.svg';
import { ReactComponent as SquaresIconGray } from 'assets/squares-icon-gray.svg';
import { ReactComponent as AssetsIcon } from 'assets/assets-icon.svg';
import { ReactComponent as AssetsIconGray } from 'assets/assets-icon-gray.svg';
import { ReactComponent as SignersIcon } from 'assets/signers-icon.svg';
import { ReactComponent as SignersIconGray } from 'assets/signers-icon-gray.svg';
import { ApplicationRoutes } from '../../../utils/routes';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import { ReactComponent as ExternalIcon } from 'assets/external-icon.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../providers/Global';
import { getAccountData } from '../../../api/userAPI';
import copyToClipboard from 'copy-to-clipboard';
import { useToast } from 'hooks/useToast';
import HashBasedAvatar from 'components/Avatar';
import { getAvatarUID } from 'utils/avatar';

const menuItems = [
  {
    name: 'Dashboard',
    route: 'account',
    defaultIcon: SquaresIconGray,
    selectedIcon: SquaresIcon,
  },
  {
    name: 'Assets',
    route: 'assets',
    defaultIcon: AssetsIconGray,
    selectedIcon: AssetsIcon,
  },
  {
    name: 'Transactions',
    route: 'transactions',
    defaultIcon: ArrowsIconGray,
    selectedIcon: ArrowsIcon,
  },
  {
    name: 'Signers',
    route: 'signers',
    defaultIcon: SignersIconGray,
    selectedIcon: SignersIcon,
  },
];

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { multisigAccountId, walletId } = useParams();
  const { updateBalance, balance, currentAccount, connectedNetwork } = useContext(GlobalContext);

  const fetchAccountData = useCallback(async () => {
    if (multisigAccountId) {
      const { balance } = await getAccountData(multisigAccountId, connectedNetwork!);
      updateBalance(balance);
    }
  }, [connectedNetwork, multisigAccountId, updateBalance]);

  useEffect(() => {
    if (multisigAccountId) {
      fetchAccountData();
    }
  }, [fetchAccountData, multisigAccountId]);

  return (
    <Flex
      p={6}
      w={318}
      gap={4}
      mr={4}
      bg="white"
      borderRadius={16}
      textAlign="center"
      direction="column"
      alignItems="center"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      <Box width="full" display="flex" flexDirection="column" alignItems="center" gap={2}>
        <HashBasedAvatar UID={getAvatarUID(walletId)} size={64} />
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Text size="sm" variant="bodyBold">
            {currentAccount.name}
          </Text>
          <Box display="flex" alignItems="center" gap={2}>
            <CopyIcon
              cursor="pointer"
              onClick={() => {
                copyToClipboard(multisigAccountId || '');
                showToast('Copied to clipboard', 'success', undefined, 'top');
              }}
            />
            <Link
              href={`https://hashscan.io/${connectedNetwork}/account/${multisigAccountId}`}
              cursor="pointer"
              isExternal
            >
              <ExternalIcon />
            </Link>
          </Box>
        </Box>
        <Box p={4} mt={2} width="full" bg="primary">
          <Text size="lg" variant="bodyBold">
            HBAR {balance.toFixed(2)}
          </Text>
        </Box>
      </Box>
      <Divider borderColor="rgba(0, 0, 0, 0.3)" my={2} />
      <Box width="full">
        {menuItems.map(menuItem => {
          const Icon = location.pathname.includes(menuItem.route)
            ? menuItem.selectedIcon
            : menuItem.defaultIcon;
          return (
            <Box
              p={4}
              mr="auto"
              width="full"
              display="flex"
              alignItems="center"
              key={menuItem.name}
              cursor="pointer"
              bg={location.pathname.includes(menuItem.route) ? '#F9F9F9' : 'transparent'}
              color={location.pathname.includes(menuItem.route) ? 'black' : '#C3C3C3'}
              onClick={() =>
                navigate(
                  `${ApplicationRoutes.Dashboard}/${walletId}/${menuItem.route}/${multisigAccountId}`,
                )
              }
            >
              <Icon />
              <Text ml={2} size="sm" variant="bodyBold">
                {menuItem.name}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Button
        w={237}
        mt="auto"
        variant="secondary"
        borderRadius={6}
        onClick={() => navigate(ApplicationRoutes.Base)}
      >
        <ArrowsIcon />
        <Text size="sm" variant="bodyBold" ml={2}>
          Switch Accounts
        </Text>
      </Button>
    </Flex>
  );
};

export default Menu;
