import { SystemStyleObject } from "@chakra-ui/react";

export const PopoverItem: SystemStyleObject = {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    width: 'full',
    p: 3,
    gap: 2,
    borderRadius: 12,
    transition: "background 0.2s ease-in-out",
    _hover: {
        bg: 'blackAlpha.200',
    },
}

export const PopoverTriggerBox: SystemStyleObject = {
    p: 3,
    px: 5,
    gap: 2,
    w: 242,
    h: 49,
    display: "flex",
    cursor: "pointer",
    borderRadius: 16,
    justifyContent: "space-between",
    alignItems: 'center',
    border: "1px solid rgba(0, 0, 0, 0.80)"
}

export const PageHolder: SystemStyleObject = {
    bg: "white",
    p: 54,
    minH: 520,
    gap: 6,
    borderRadius: 16,
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
}
