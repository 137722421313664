import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import TransactionListHeader from './TransactionHeader';
import TransactionItem from './TransactionItem';
import { useLocation, useParams } from 'react-router-dom';
import { getWalletTransactions } from '../../../api/multisigAPI';
import { TransactionType } from '../../../types/transactions';
import { ReactComponent as ArrowsIconGreen } from 'assets/arrows-icon-green.svg';
import { ReactComponent as ArrowsIconWhiteGray } from 'assets/arrows-icon-white-gray.svg';
import { GlobalContext } from '../../../providers/Global';
import { ModalType, SCREEN_LG, SCREEN_SM } from '../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { ApplicationRoutes } from 'utils/routes';

const TransactionList = () => {
  const location = useLocation();
  const { walletId, multisigAccountId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { openModal } = useContext(GlobalContext);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });
  const isScreenLarge = useMediaQuery({ query: `(max-width: ${SCREEN_LG})` });

  const fetchTransactions = useCallback(async () => {
    if (walletId) {
      const result = await getWalletTransactions(walletId);
      if (result?.data) {
        setTransactions(result.data);
      }
      setIsLoading(false);
    }
  }, [walletId]);

  useEffect(() => {
    if (walletId) {
      fetchTransactions();
    }
  }, [fetchTransactions, walletId]);

  return transactions.length > 0 ? (
    <Flex
      flex={1}
      p={6}
      pt={isScreenSmall ? 4 : 6}
      bg="white"
      borderRadius={16}
      flexDirection="column"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      {location.pathname.includes('account') && (
        <Text
          size="lg"
          fontWeight={300}
          color="blackAlpha.500"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Recent Transactions
          {
            isScreenLarge && (
              <ChakraLink
                as={RouterLink}
                to={`${ApplicationRoutes.Dashboard}/${walletId}/transactions/${multisigAccountId}`}
                cursor="pointer"
                fontSize="sm"
                fontWeight="500"
                letterSpacing="0.5px"
                color="blackAlpha.900"
                textDecoration="none !important"
              >
                View
              </ChakraLink>
            )
          }
        </Text>
      )}
      <TransactionListHeader />
      <Box display="flex" width="full" flexDirection="column">
        {transactions.map((transaction: TransactionType) => {
          return <TransactionItem key={transaction._id} transaction={transaction} />;
        })}
      </Box>
    </Flex>
  ) : !isLoading && location.pathname.includes('transactions') ? (
    <Flex flexDirection="column" alignItems="center" mt={5}>
      <ArrowsIconGreen />
      <Text size="2xl" variant="bodyBold">
        There are no Transactions yet.
      </Text>
      <Text mt={1} size="xl">
        Create new transaction from the button.
      </Text>
    </Flex>
  ) : !isLoading ? (
    <Flex
      p={6}
      bg="white"
      height="100%"
      borderRadius={16}
      flexDirection="column"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      {
        location.pathname.includes('account') && (
          <Text size="lg" color="blackAlpha.500" fontWeight={300} mb={6}>
            Recent Transactions
          </Text>
        )
      }
      <Flex flexDirection="column" alignItems="center" mt={5} gap={8}>
        <ArrowsIconWhiteGray />
        <Flex flexDirection="column" gap={1} alignItems="center">
          <Text size="2xl">There are no Transactions yet.</Text>
          <Text size="sm">Create new transaction from the button below.</Text>
        </Flex>
        <Button
          w={200}
          variant="primary"
          onClick={() => openModal(ModalType.CreateTransactionModal)}
        >
          New Transaction
        </Button>
      </Flex>
    </Flex>
  ) : (
    <Box textAlign="center" mt="120px">
      <Spinner size="lg" />
    </Box>
  );
};

export default TransactionList;
