import {
  Flex,
  Box,
  Text,
  Input,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as AccountSettingsIcon } from 'assets/account-settings-icon-green.svg';
import { ReactComponent as HederaLogo } from 'assets/hedera-logo.svg';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../providers/Global';
import { ApplicationRoutes } from '../utils/routes';
import { importMultisigAccount } from '../api/multisigAPI';
import { useToast } from '../hooks/useToast';
import { getAccountDataByNetwork } from 'api/userAPI';
import { PageHolder } from './styles/LoadAccountPageStyles';
import { handleNetworkColor } from 'utils/color';
import { useMediaQuery } from 'react-responsive';
import { ReactPortal } from 'hooks/usePortal';
import { isValidAccount } from 'utils/general';
import { PublicKey } from '@hashgraph/sdk';
import { proto } from '@hashgraph/proto';
import { AccountData, ImportAccount } from 'types/account';
import { AxiosError } from 'axios';

const LoadAccountPage = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const [accountName, setAccountName] = useState('');
  const [newAccountId, setNewAccountId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    connection: { accountId },
    connectedNetwork
  } = useContext(GlobalContext);

  useEffect(() => {
    if (!accountId) {
      navigate(ApplicationRoutes.Base);
    }
  }, [accountId, navigate]);

  const loadAccount = async () => {
    try {
      setIsLoading(true);
      const accountData = await getAccountDataByNetwork(
        newAccountId,
        connectedNetwork!,
      );

      const extractKeyInfo = (accountData: AccountData): Omit<ImportAccount, 'name' | 'network'> => {
        const { key } = accountData;
        if (key && key._type === 'ProtobufEncoded') {
          const keyBytes = Buffer.from(key.key, 'hex');
          const protoKey = proto.Key.decode(keyBytes);

          if (protoKey.thresholdKey) {
            const keys = protoKey.thresholdKey.keys?.keys ?? [];
            return {
              accountId: accountData.account,
              key,
              threshold: Number(protoKey.thresholdKey.threshold),
              keys: keys.map((k: any) => {
                return PublicKey.fromBytesED25519(k.ed25519).toString();
              }),
            };
          }

          if (protoKey.keyList) {
            const keys = protoKey.keyList.keys ?? [];
            return {
              accountId: accountData.account,
              key,
              threshold: keys.length || 1,
              keys: keys.map((k: any) => {
                return PublicKey.fromBytesED25519(k.ed25519).toString();
              }),
            };
          }
        }

        return {
          accountId: accountData.account,
          key,
        };
      };

      const accountInfo = extractKeyInfo(accountData);

      await importMultisigAccount({
        name: accountName,
        network: connectedNetwork!,
        ...accountInfo,
      });

      navigate(ApplicationRoutes.Base);
      showToast('Successfully loaded', 'success', undefined, 'top-right');
    } catch (err) {
      const axiosError = err as AxiosError<{ statusCode: number; message: string }>;

      if (axiosError.response?.data?.statusCode === 400) {
        showToast(axiosError.response?.data?.message, 'error', undefined, 'top-right');
        return;
      }

      showToast('Invalid address or network', 'error', undefined, 'top-right');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ReactPortal
      id="extras"
      withHeader
      disablePortal={!isMobile}
    >
      <Flex
        flex={1}
        flexDirection="column"
        maxW={874}
        w="full"
        p={isTablet ? 6 : 0}
        pt={isTablet ? 12 : 24}
        bg={isMobile ? '#fff' : undefined}
        height={isTablet ? '100%' : 'auto'}
      >
        <Flex
          sx={isMobile ? undefined : PageHolder}
          rowGap={isTablet ? 6 : 0}
          direction="column"
          height={isTablet ? '100%' : 'auto'}
        >
          <Flex gap={6}>
            {
              !isTablet && (
                <Box>
                  <AccountSettingsIcon />
                </Box>
              )
            }
            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <Text size="lg" variant="bodyBold">
                  Set name, network &amp; address
                </Text>
              </Box>
              <Box>
                <Text size="sm" color="blackAlpha.600">
                  Paste the address of your account, set name and choose network.
                </Text>
              </Box>
            </Box>
          </Flex>
          <Box
            display="flex"
            borderRadius={16}
            gap={4}
            px={isTablet ? 0 : 20}
          >
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              gap={6}>
            <Input
              size="md"
              h="50px"
              borderRadius={12}
              value={accountName}
              onChange={event => {
                const value = event?.target.value;
                setAccountName(value);
              }}
              placeholder="Enter Account name"
            />
            <Input
              size="md"
              h="50px"
              borderRadius={12}
              value={newAccountId}
              isInvalid={!!newAccountId.length ? !isValidAccount(newAccountId) : false}
              onChange={event => {
                const value = event?.target.value;
                setNewAccountId(value);
              }}
              placeholder="Enter Account address"
            />
          </Box>
            {
              connectedNetwork && (
                <Box gap={2} display={'flex'} mt={3} alignItems={'flex-start'}>
                  <HederaLogo />
                  <Text textTransform={'capitalize'} color={handleNetworkColor(connectedNetwork)}> {connectedNetwork}</Text>
                </Box>
              )
            }
          </Box>
        </Flex>
        <Box
          flex={1}
          pt={12}
          display="flex"
          justifyContent={isMobile ? "center" : "space-between"}
        >
          {
            !isMobile && (
              <Button
                w={203}
                borderRadius={6}
                variant="secondary"
                isLoading={isLoading}
                onClick={() => navigate(ApplicationRoutes.Base)}
              >
                Back
              </Button>
            )
          }
          <Button
            borderRadius={6}
            w={isMobile ? '100%' : 203}
            variant="primary"
            onClick={loadAccount}
            isLoading={isLoading}
            isDisabled={!(accountName && newAccountId)}
          >
            Import account
          </Button>
        </Box>
      </Flex>
    </ReactPortal>
  );
};

export default LoadAccountPage;
