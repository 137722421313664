import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ReactComponent as ArrowsIconGreen } from 'assets/arrows-icon-green.svg';
import { useContext } from 'react';
import { GlobalContext } from '../../providers/Global';
import { ModalType, SCREEN_MD } from '../../utils/constants';
import { useMediaQuery } from 'react-responsive';

type TransactionType = {
  name: string;
  modalType: ModalType;
  isDisabled: boolean;
}

const TRANSACTION_TYPES: TransactionType[] = [
  {
    name: "Send Tokens",
    modalType: ModalType.SendTokensModal,
    isDisabled: false
  },
  {
    name: "Create Token",
    modalType: ModalType.CreateTokenModal,
    isDisabled: true
  },
  {
    name: "Contract Interaction",
    modalType: ModalType.ContractInteractionModal,
    isDisabled: true
  },
];

const CreateTransactionModal = () => {
  const {
    modals: {
      [`${ModalType.CreateTransactionModal}`]: { open },
    },
    openModal,
    closeModal,
  } = useContext(GlobalContext);

  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });

  const TransactionBtn = ({ idx }: { idx: number }) => {
    const tx = TRANSACTION_TYPES[idx];
    const tooltipLable = tx.isDisabled ? 'Coming soon' : '';
    return (
      <Tooltip
        label={tooltipLable}
        children={
          <Button
            isDisabled={tx.isDisabled}
            w={250}
            variant={'secondary'}
            borderRadius={8}
            onClick={() => {
              openModal(tx.modalType);
            }}
          >
            {tx.name}
          </Button>
        }
      />
    )
  };

  return (
    <Modal onClose={() => closeModal(ModalType.CreateTransactionModal)} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="586px"
        w="full"
        mx={isScreenMedium ? 4 : 0}
      >
        <ModalHeader pt={6} px={6} pb={0}>
          <Flex alignItems="center" flexDirection="column">
            <ArrowsIconGreen width={56} height={56} style={{ marginTop: 32 }} />
            <Text my={2} size="2xl" variant="bodyBold">
              Create Transaction
            </Text>
          </Flex>
          <ModalCloseButton
            mt={2}
            mr={2}
            color="#565656"
            _hover={{ border: 'none' }}
            _focusVisible={{ outline: 'none' }}
          />
        </ModalHeader>
        <ModalBody pb={14}>
          <Flex alignItems="center" flexDirection="column" gap={4}>
            {TRANSACTION_TYPES.map((type, idx) => (
              <TransactionBtn
                key={idx + type.name}
                idx={idx}
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateTransactionModal;
