import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useToast } from 'hooks/useToast';
import { GlobalContext } from 'providers/Global';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as HederaLogo } from 'assets/hedera-hbar-logo.svg';
import { ReactComponent as HashpackLogo } from 'assets/hashpack-wallet-logo.svg';
import { ReactComponent as GreenWalletIcon } from 'assets/wallet-icon-green.svg';
import { NetworkType, SupportedNetworks, SupportedWallets } from 'utils/constants';


const styles = {
  popoverItem: {
    maxH: 20,
    p: 1.5,
    gap: 2,
    width: "full",
    display: "flex",
    cursor: "pointer",
    borderRadius: 8,
    alignItems: "center",
    bg: "#F9F9F9",
    _hover: {
      background: 'rgba(0, 0, 0, 0.08)',
      '& [data-radio]': {
        borderColor: 'rgba(0, 0, 0, 0.24)',
      },
    },
  },
  radio: (isSelected: boolean) => ({
    position: "absolute",
    top: "50%",
    right: 3,
    width: 5,
    height: 5,
    bg: "#fff",
    border: "2px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "100%",
    transform: "translateY(-50%)",
    ...(isSelected ? {} : { borderColor: "rgba(0, 0, 0, 0.12) !important" }),
    "::before": {
      content: '""',
      position: 'absolute',
      bg: isSelected ? "#2ECB3B" : "transparent",
      inset: "3px",
      borderRadius: "100%",
    }
  })
};

interface IModalMenuProps {
  onClose: () => void;
}

export const ModalMenu = (props: IModalMenuProps) => {
  const { onClose } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const {
    connection: { accountId, disconnectWallet },
    connectedNetwork,
    updateConnectorNetwork
  } = useContext(GlobalContext);

  const handleSelection = (network: SupportedNetworks) => {
    updateConnectorNetwork(network.toLowerCase() as NetworkType)
  };

  return (
    <Modal
      isOpen
      isCentered
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent width={isMobile ? "80vw" : "400px"}>
        <ModalHeader pt={6} px={6} pb={0} display="flex" alignItems="center">
          <Text size="lg" variant="bodyBold">
            Connect Wallet
          </Text>
          <ModalCloseButton color="#565656" mt={3} mr={2} />
        </ModalHeader>
        <ModalBody pt={6} pb={8} px={6}>
          <Text size="sm" variant="semiBold" mb={2}>
            Network
          </Text>
          <Flex
            direction="column"
            rowGap={2}
          >
            {Object.entries(SupportedNetworks)
              .filter(([_, n]) => n !== SupportedNetworks.Previewnet)
              .map(([key, network]) =>
                <Flex
                  position="relative"
                  cursor={'pointer'}
                  height={50}
                  gap={2}
                  alignItems={'center'}
                  key={key}
                  sx={styles.popoverItem}
                  onClick={() => handleSelection(network)}
                >
                  <HederaLogo style={{ marginTop: '5px' }} />
                  <Text fontWeight={600} size="sm">{network}</Text>

                  <Box
                    sx={styles.radio(!!connectedNetwork && network.toLowerCase() === connectedNetwork)}
                    data-radio
                  />
                </Flex>
              )}
          </Flex>

          <Divider my={6} borderColor="blackAlpha.300" />

          <Text size="sm" variant="semiBold" mb={2}>
            Wallet
          </Text>

          <Flex
            flexDirection="column"
            gap={2}
          >
            {accountId ? (
              <Flex gap={4} flexDirection="column">
                <Flex
                  gap={2}
                  w={"fit-content"}
                  alignItems="center"
                >
                  <GreenWalletIcon style={{ maxHeight: '24px', maxWidth: '24px' }} />
                  <Text mt="2px" size="sm" variant="bodyBold" mr="auto">
                    {accountId ? accountId : 'Connect Wallet'}
                  </Text>
                </Flex>
                <Button
                  variant="secondary"
                  borderRadius={6}
                  onClick={() => {
                    onClose();
                    disconnectWallet();
                  }}
                >
                  Disconnect
                </Button>
              </Flex>
            ) : <PopoverItems />}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ModalMenu.displayName = 'ModalMenu';


type TPopoverItems = {
  logo: JSX.Element;
  text: string;
  clickHandler: () => void;
  isDisabled: boolean;
}

const PopoverItems = () => {

  const { connection: { connectWallet, hashconnectConnectorInstance } } = useContext(GlobalContext);
  const { onClose } = useDisclosure();
  const { showNoExtensionToast } = useToast();

  const handleWalletConnection = async () => {
    onClose();
    if (hashconnectConnectorInstance.extensionInstalled) {
      connectWallet();
    } else {
      showNoExtensionToast();
    }
  };

  const popoverItems: TPopoverItems[] = [
    {
      text: SupportedWallets.Hashpack,
      logo: <HashpackLogo style={{ maxHeight: '40px' }} />,
      clickHandler: handleWalletConnection,
      isDisabled: false
    },
  ]

  return <Flex flexDirection="column">
    {
      popoverItems.map((item, idx) => {
        if (!item.isDisabled) {
          return (
            <Box
              key={`wallet-widget-x${idx}`}
              sx={styles.popoverItem}
              onClick={item.clickHandler}
            >
              {item.logo}
              <Text size="sm">
                {item.text}
              </Text>
            </Box>
          )
        }

        return null;
      })
    }
  </Flex>
};
