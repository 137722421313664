import { Box, Flex, Text } from '@chakra-ui/react';
import { TransactionListLabels } from '../../../types/transactions';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_SM } from 'utils/constants';

const TransactionListHeader = () => {
  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  return (
    <Flex
      width="full"
      alignItems="center"
      h={10}
      p={11}
      mt={isScreenSmall ? 2 : 6}
      bg="primary"
      borderTopRadius={isScreenSmall ? 0 : 8}
    >
      {
        !isScreenSmall && (
          <Box flex={1}>
            <Text size="xs">{TransactionListLabels.ID}</Text>
          </Box>
        )
      }
      <Box flex={2}>
        <Text size="xs">{TransactionListLabels.ACTION}</Text>
      </Box>
      <Box flex={2}>
        <Text size="xs">{TransactionListLabels.AMOUNT}</Text>
      </Box>
      <Box flex={2}>
        <Text size="xs">{TransactionListLabels.EXPIRES}</Text>
      </Box>
      {
        !isScreenSmall && (
          <Box flex={1}>
            <Text size="xs">{TransactionListLabels.THRESHOLD}</Text>
          </Box>
        )
      }

      {
        !isScreenSmall && (
          <Box minW={155} flex={1} display="flex" alignItems="center">
            <Text ml="auto" mr="50px" size="xs">
              {TransactionListLabels.STATUS}
            </Text>
          </Box>
        )
      }
    </Flex>
  );
};

export default TransactionListHeader;
