import React, { useEffect, useState } from 'react';
import Hashconnect from '../connectors/hashconnect';
import { MultisigAccount } from '../types/account';
import { ModalType, NetworkType, SupportedNetworks } from '../utils/constants';

interface GlobalContextType {
  connection: {
    accountId: string;
    connectWallet: () => void;
    disconnectWallet: () => void;
    hashconnectConnectorInstance: Hashconnect;
  };
  modals: { [key: string]: { open: boolean } };
  sdk: any;
  balance: number;
  currentAccount: MultisigAccount;
  updateAccount: (account: MultisigAccount) => void;
  updateBalance: (balance: number) => void;
  openModal: (modal: ModalType) => void;
  closeModal: (modal: ModalType) => void;
  connectedNetwork: NetworkType | undefined;
  updateConnectorNetwork: (network: NetworkType) => void;
  isPairing: boolean;
}

const initialModalsState = {
  [`${ModalType.FailedModal}`]: { open: false },
  [`${ModalType.SendTokensModal}`]: { open: false },
  [`${ModalType.CreateTokenModal}`]: { open: false },
  [`${ModalType.ConnectWalletModal}`]: { open: false },
  [`${ModalType.CreateTransactionModal}`]: { open: false },
  [`${ModalType.ContractInteractionModal}`]: { open: false },
}

const contextInitialValue: GlobalContextType = {
  connection: {
    accountId: '',
    connectWallet: () => { },
    disconnectWallet: () => { },
    hashconnectConnectorInstance: {} as Hashconnect,
  },
  modals: initialModalsState,
  sdk: {},
  balance: 0,
  currentAccount: {} as MultisigAccount,
  updateAccount: (account: MultisigAccount) => { },
  updateBalance: (balance: number) => { },
  openModal: (modal: ModalType) => { },
  closeModal: (modal: ModalType) => { },
  connectedNetwork: undefined,
  updateConnectorNetwork: (network: NetworkType) => { },
  isPairing: false
};

export const GlobalContext = React.createContext(contextInitialValue);

const getDefaultNetwork = (): NetworkType => {
  const currentNetwork = localStorage.getItem('connectedNetwork') as NetworkType || undefined;
  if (!currentNetwork) {
    const network = (process.env.NODE_ENV === 'development' ? SupportedNetworks.Testnet : SupportedNetworks.Mainnet).toLowerCase() as NetworkType;
    localStorage.setItem('connectedNetwork', network);

    return network;
  }

  return currentNetwork;
}

interface IGlobalProps {
  children: React.ReactNode;
}

export const GlobalProvider = ({ children }: IGlobalProps) => {
  const [sdk] = useState({});
  const [connected, setConnected] = useState(false);
  const [hashconnectConnectorInstance, setHashconnectConnectorInstance] = useState<Hashconnect>();
  const [accountId, setAccountId] = useState(localStorage.getItem('accountId') || '');
  const [balance, setBalance] = useState(0);
  const [currentAccount, setCurrentAccount] = useState<MultisigAccount>({});
  const [modals, setModals] = useState(initialModalsState);
  const [connectedNetwork, setConnectedNetwork] = useState<NetworkType | undefined>(getDefaultNetwork());
  const [isPairing, setIsPairing] = useState<boolean>(false);

  const updateAccount = (account: MultisigAccount) => {
    setCurrentAccount(account);
  };

  const updateConnectorNetwork = (network: NetworkType) => {
    console.log('@@@ disconnectWallet', network)
    if (network !== connectedNetwork) {
      disconnectWallet();
      setConnectedNetwork(network);
      localStorage.setItem('connectedNetwork', network);
    }
  };

  const updateBalance = (balance: number) => {
    setBalance(balance);
  };

  const openModal = (modal: ModalType) => {
    setModals({
      ...contextInitialValue.modals,
      [`${modal}`]: {
        open: true,
      },
    });
  };

  const closeModal = (modal: ModalType) => {
    setModals({
      ...modals,
      [`${modal}`]: {
        open: false,
      },
    });
  };

  const connectWallet = () => {
    setIsPairing(true);
    hashconnectConnectorInstance?.connectToExtension();
  };

  const disconnectWallet = () => {
    hashconnectConnectorInstance?.clearPairings();
    setAccountId('');

    // Clear all local storage
    localStorage.clear();

    // Reset connected network to default
    setConnectedNetwork(getDefaultNetwork());
  };

  const connection = {
    connected,
    accountId,
    connectWallet,
    disconnectWallet,
    hashconnectConnectorInstance: hashconnectConnectorInstance || ({} as Hashconnect),
  };
  const contextValue = {
    sdk,
    connection,
    modals,
    openModal,
    closeModal,
    balance,
    updateBalance,
    currentAccount,
    updateAccount,
    connectedNetwork,
    updateConnectorNetwork,
    isPairing
  };

  useEffect(() => {
    const initHashconnectConnector = async (network: NetworkType) => {
      const hashconnectConnector = new Hashconnect(setAccountId, setConnected);

      await hashconnectConnector.initHashconnect(network);
      setHashconnectConnectorInstance(hashconnectConnector);
    };

    if (connectedNetwork) {
      initHashconnectConnector(connectedNetwork);
    }
  }, [connectedNetwork]);

  useEffect(() => {
    if (connection?.accountId) {
      setIsPairing(false);
    }
  }, [connection?.accountId])

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
