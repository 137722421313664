export enum AbiMethodTypes {
    String = 'string',
    Address = 'address',
    Bool = 'bool',
    Int256 = 'int256',
    Int = 'int',
    Uint256 = 'uint256',
    Uint = 'uint',
    // TODO: Add more
}

export interface AbiFunctionInput {
    selectedMethodInputs: any,
    input: any,
    index: number,
    setter: (index: number, value: any) => void;
};
export interface InputComponentProps {
    value: any,
    placeholder: string,
    index: number | string,
    isInvalid?: boolean,
    isDisabled?: boolean,
    notEnoughBalance?: boolean,
    setter: (index: any, value: any) => void;
};
