import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { authenticate } from '../../api/userAPI';
import { GlobalContext } from '../../providers/Global';
import SelectWalletNetworksModal from '../Modals/ConnectWalletModal';
import ContractInteractionModal from '../Modals/ContractInteractionModal';
import CreateTransactionModal from '../Modals/CreateTransactionModal';
import CreateTokenModal from '../Modals/CreateTokenModal';
import FailedModal from '../Modals/FailedModal';
import SendTokensModal from '../Modals/SendTokensModal';
import { Header } from './Header';

const Layout = () => {
  const {
    connectedNetwork,
    connection: { accountId },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (accountId) {
      authenticate(accountId, connectedNetwork!);
    }
  }, [accountId, connectedNetwork]);

  return (
    <>
      <FailedModal />
      <SendTokensModal />
      <CreateTransactionModal />
      <ContractInteractionModal />
      <SelectWalletNetworksModal />
      <CreateTokenModal />
      <Grid
        templateAreas={`
          "header header"
          "main main"
          "main main"
        `}
        gridTemplateRows={'64px 1fr 300px'}
        gridTemplateColumns={'100% 1fr'}
        height="100%"
      >
        <GridItem area="header" zIndex={9999}>
          <Header />
        </GridItem>
        <GridItem
          id="content"
          display="flex"
          flexDirection="column"
          area="main"
          bg="#FAFAFA"
          overflowY="scroll"
        >
          <Flex
            flex={1}
            order={2}
            direction="column"
            alignItems="center"
            py={3}
            px={{ base: 6, md: 12 }}
          >
            <Outlet />
          </Flex>
        </GridItem>
        {/* <GridItem area="footer"></GridItem> */}
      </Grid>
    </>
  );
};

export default Layout;
