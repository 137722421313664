import { Flex, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { ReactComponent as HederaLogo } from 'assets/hedera-hbar-logo.svg';
import { ReactComponent as ArrowDownBlack } from 'assets/arrow-down-black-icon.svg';
import { useCallback, useContext, useRef } from 'react';
import { GlobalContext } from '../../providers/Global';
import { handleNetworkColor } from '../../utils/color';
import { NetworkType, SupportedNetworks } from 'utils/constants';
import { styles } from './styles';

const NetworksWidget = () => {
  const { connectedNetwork, updateConnectorNetwork } = useContext(GlobalContext);

  const flexRef = useRef(null);

  const handleNetworkName = useCallback((): string => {
    return connectedNetwork ? connectedNetwork : "Network"
  }, [connectedNetwork]);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSelection = (network: SupportedNetworks) => {
    onClose();
    updateConnectorNetwork(network.toLowerCase() as NetworkType)
  };

  // This overrides some really odd behaviour 
  // of the Popover element handling itself open/closed state
  useOutsideClick({
    ref: flexRef,
    handler: onClose
  });

  return (
    <Flex ml={4} w={140} alignItems="center">
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <Flex
            ref={flexRef}
            onClick={isOpen ? onClose : onOpen}
            gap={2}
            sx={{ cursor: 'pointer', alignItems: 'center' }}
          >
            <HederaLogo />
            <Text
              mb="2px"
              size="sm"
              mr="auto"
              textTransform={'capitalize'}
              fontWeight={600}
              color={handleNetworkColor(connectedNetwork)}
            >
              {handleNetworkName()}
            </Text>
            <ArrowDownBlack style={styles.rotatingArrow(isOpen)} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent mt={7} p={2} w={150} borderRadius={16} _focusVisible={{ outline: 'none ' }}>
          <PopoverBody p={0}>
            <Flex gap={2} flexDirection="column">
              {Object.entries(SupportedNetworks)
                .filter(([_, n]) => n.toLowerCase() !== connectedNetwork && n !== SupportedNetworks.Previewnet)
                .map(([key, network]) =>
                  <Flex
                    cursor={'pointer'}
                    height={50}
                    gap={2}
                    alignItems={'center'}
                    key={key}
                    sx={styles.popoverItem}
                    onClick={() => handleSelection(network)}
                  >
                    <HederaLogo style={{ marginTop: '5px' }} />
                    <Text fontWeight={600} size="sm">{network}</Text>
                  </Flex>
                )}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default NetworksWidget;
