import { Box, Text, Flex } from '@chakra-ui/react';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import { useContext } from 'react';
import { GlobalContext } from '../../providers/Global';
import { Signer } from '../../types/account';
import copyToClipboard from 'copy-to-clipboard';
import { useToast } from 'hooks/useToast';
import { MultisigRoles, SCREEN_SM } from 'utils/constants';
import { useParams } from 'react-router-dom';
import { ApplicationRoutes } from 'utils/routes';
import { MobileMenu } from './components/MobileMenu';
import { useMediaQuery } from 'react-responsive';

const SignersPage = () => {
  const { walletId, multisigAccountId } = useParams();
  const { showToast } = useToast();
  const { currentAccount, connection: { accountId: ownID } } = useContext(GlobalContext);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  return (
    <Flex
      flex={1}
      flexDirection="column"
    >
      <MobileMenu
        pageName='Signers'
        backUrl={`${ApplicationRoutes.Dashboard}/${walletId}/account/${multisigAccountId}`}
      />
      <Flex
        p={6}
        bg="white"
        height="100%"
        borderRadius={16}
        flexDirection="column"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      >
        <Flex width="full" alignItems="center" h={10} gap={4} borderRadius={6} p={4} bg="primary">
          <Box flex={3} isTruncated>
            <Text size="xs">User Name</Text>
          </Box>
          {
            !isScreenSmall && (
              <Box flex={3} isTruncated>
                <Text size="xs">User Type</Text>
              </Box>
            )
          }
          <Box flex={2} isTruncated>
            <Text size="xs">
              {isScreenSmall ? '' : 'Wallet '}Address
            </Text>
          </Box>
        </Flex>
        <Box display="flex" width="full" flexDirection="column">
          {currentAccount?.keys?.map((signer: Signer, index: number) => {
            const isOwnAccount = signer.accountId === ownID;
            return (
              <Flex
                key={index}
                gap={4}
                h={14}
                p={4}
                width="full"
                borderRadius={8}
                alignItems="center"
                borderY="1px solid #F6F6F6"
              >
                <Box flex={3} overflow="hidden">
                  <Text size="xs" isTruncated>{isOwnAccount ? MultisigRoles.OwnAddress : signer.name}</Text>
                </Box>
                {
                  !isScreenSmall && (
                    <Box flex={3}>
                      <Text size="xs">Owner</Text>
                    </Box>
                  )
                }
                <Box flex={2} display="flex" alignItems="center" gap={1}>
                  <Text size="xs">{signer.accountId}</Text>
                  <CopyIcon
                    width={16}
                    height={16}
                    cursor="pointer"
                    onClick={() => {
                      copyToClipboard(signer.accountId);
                      showToast('Copied to clipboard', 'success', undefined, 'top');
                    }}
                  />
                </Box>
              </Flex>
            );
          })}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SignersPage;
