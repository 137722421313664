import axios, { AxiosInstance } from 'axios';

export const getAuthAxiosInstance = (): AxiosInstance | void => {
    const token = localStorage.getItem('token');
    if (token) {
        return axios.create({
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }
};
