import { Box, Flex, Switch } from "@chakra-ui/react";
import { InputComponentProps } from "types/abi";
import { getInputLabel } from "../hepers";
import { styles } from "../styles";

const BooleanInputComponent = ({ value, placeholder, index, setter }: InputComponentProps) => {
    return (
        <Flex mt={2} gap={5} flexDirection="column">
            {getInputLabel(placeholder, true)}
            <Flex pl={2} gap={2} alignItems={'center'}>
                <Box sx={styles.booleanInputComponent(!value)}>NO</Box>
                <Switch
                    isChecked={value}
                    onChange={(event) => setter(index, event.target.checked)}
                />
                <Box sx={styles.booleanInputComponent(value)}>YES</Box>
            </Flex>
        </Flex>
    )
};

export default BooleanInputComponent;
