import { Flex, Button, Box, Text, Input } from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as AccountSettingsIcon } from 'assets/account-settings-icon-green.svg';
import { useEffect, useRef, useState, useContext } from 'react';
import { CreateAccount, Signer } from '../../types/account';
import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { GlobalContext } from 'providers/Global';
import { MultisigRoles, SCREEN_SM } from 'utils/constants';
import { getSignerAccount } from './CreateAccountPage';
import { useMediaQuery } from 'react-responsive';
import { isValidAccount } from 'utils/general';

interface OwnProps {
  data: CreateAccount;
  setData: (data: CreateAccount) => void;
  setIsInvalid: any;
}

const SignersForm = ({ data, setData, setIsInvalid }: OwnProps) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const refAddButton = useRef<HTMLButtonElement>(null);

  const { connection: { accountId } } = useContext(GlobalContext);
  const [signersData, setSignersData] = useState<Signer[]>([]);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  useEffect(() => {
    if (data.accounts) {
      setSignersData(data.accounts);
    }
  }, [data.accounts]);

  const handleNameChange = (event: { target: { value: string } }, index: number) => {
    const updatedSigner = signersData[index];
    updatedSigner.name = event.target.value;
    signersData[index] = updatedSigner;
    handleData(signersData, data);
  };

  const handleAddressChange = (event: { target: { value: string } }, index: number) => {
    let updatedSigner = signersData[index];
    updatedSigner.accountId = event.target.value;
    if (!!accountId && updatedSigner.accountId === accountId) {
      signersData.splice(index, 1);
      signersData.unshift(updatedSigner);
    } else {
      signersData[index] = updatedSigner;
    };
    handleData(signersData, data);
  };

  const validateAddress = (address: string, inputIndex: number) => {
    let isUsed = false;
    if (address) {
      signersData.forEach((signer, index) => {
        if (signer.accountId === address && index !== inputIndex) {
          isUsed = true;
        }
      });
    }


    if (!!address.length && !isUsed) {
      const isInvalidValid = !isValidAccount(address);
      setIsInvalid(isInvalidValid);
      return isInvalidValid;
    }

    setIsInvalid(isUsed);
    return isUsed;
  };

  const handleDelete = (index: number, isOwnAccount: boolean = false) => {
    signersData?.splice(index, 1);
    const replaceWithEmpty = signersData.length === 0 && isOwnAccount;
    if (replaceWithEmpty) {
      handleData([getSignerAccount()], data)
      return
    }
    handleData(signersData, data);
  };

  const handleData = (signersData: Signer[], data: CreateAccount) => {
    setSignersData([...signersData]);
    setData({
      ...data,
      accounts: [...signersData],
    });
  };

  const isAccountIdPresent = (signersData: Signer[], accountId: string): boolean => {
    return !!signersData.find((signer) => signer.accountId === accountId);
  }

  const addAccountId = (
    signersData: Signer[],
    name: string = '',
    accountId: string = '',
    data: CreateAccount
  ) => {
    handleData([
      getSignerAccount(name, accountId),
      ...signersData],
      data
    )
  }

  useEffect(() => {
    refAddButton.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
  }, [signersData]);

  const showWarningMessage = !isAccountIdPresent(signersData, accountId);

  return (
    <Flex
      direction="column"
      gap={6}
      bg={!isScreenSmall ? "white" : ""}
      boxShadow={!isScreenSmall ? "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)" : ""}
      borderRadius={16}
      pb={isScreenSmall ? 0 : "30px"}
    >
      <Flex
        p={!isScreenSmall ? 54 : "24px"}
        pb={0}
        direction="column"
        gap={2}
      >
        <Text
          position="relative"
          display="flex"
          pl={isScreenSmall ? "0" : "80px"}
          size="lg"
          variant="bodyBold"
        >
          <Box
            as="span"
            position="absolute"
            top={0}
            left={0}
            display={isScreenSmall ? "none" : "block"}
          >
            <AccountSettingsIcon />
          </Box>
          Signers list
        </Text>
        <Text
          pl={isScreenSmall ? "0" : "80px"}
          size="sm"
          color="blackAlpha.600"
        >
          Please, add the addresses of the signers you wish to include in this wallet.
          {
            !isScreenSmall && (
              <>
                You can add custom names to each signer.
                Your address is automatically included.
                You can remove it if you wish to do so, but be mindful that you will create a wallet of which you will not be a part of.
              </>
            )
          }
        </Text>
      </Flex>

      <Flex
        ref={refContainer}
        flex={1}
        flexDirection="column"
        overflow="hidden"
        gap={4}
        maxH={refContainer.current?.clientHeight || 'auto'}
      >
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
          overflow="scroll"
          py={0.5}
          mx={isScreenSmall ? '0' : "24px"}
          gap={6}
        >
          {
            signersData.map((signer: Signer, index: number) => {
            const isOwnAccount = signer.accountId === accountId
            return (
              <Flex
                key={index}
                w="full"
                rowGap={4}
                pl={isScreenSmall ? '24px' : "30px"}
                pr={isScreenSmall ? '24px' : "14px"}
                direction={isScreenSmall ? 'column' : 'row'}
                alignContent="stretch"
              >
                {
                  isScreenSmall && (
                    <Flex justifyContent="space-between" alignItems="center" mb={-4}>
                      <Text size="sm" variant="semiBold">
                        Signer {index + 1}
                      </Text>

                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        minW={12}
                        minH={12}
                        maxW={12}
                        maxH={12}
                        w="full"
                        h="full"
                        cursor="pointer"
                        onClick={() => handleDelete(index, isOwnAccount)}
                      >
                        <DeleteIcon />
                      </Flex>
                    </Flex>
                  )
                }
                <Input
                  disabled={isOwnAccount}
                  w={isScreenSmall ? 'auto' : 205}
                  size="lg"
                  borderRadius={16}
                  value={isOwnAccount ? MultisigRoles.OwnAddress : signer.name}
                  onChange={event => handleNameChange(event, index)}
                  placeholder="Owner name"
                />
                <Input
                  ml={isScreenSmall ? 0 : 4}
                  disabled={isOwnAccount}
                  w={isScreenSmall ? 'auto' : '70%'}
                  size="lg"
                  borderRadius={16}
                  value={signer.accountId}
                  isInvalid={validateAddress(signer.accountId, index)}
                  onChange={event => handleAddressChange(event, index)}
                  placeholder="Owner address"
                />
                {
                  (!isScreenSmall && (isOwnAccount || signersData?.length > 1)) && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      minW={12}
                      minH={12}
                      maxW={12}
                      maxH={12}
                      w="full"
                      h="full"
                      cursor="pointer"
                      onClick={() => handleDelete(index, isOwnAccount)}
                    >
                      <DeleteIcon />
                    </Flex>
                  )
                }
              </Flex>
            );
            })
          }
          <Button
            ref={refAddButton}
            w={122}
            h={8}
            p={1}
            pr={2}
            variant="ghost"
            onClick={() => {
              if (signersData) {
                setSignersData([
                  ...signersData,
                  {
                    name: '',
                    accountId: '',
                    accountKey: '',
                  },
                ]);
                setData({
                  ...data,
                  accounts: [
                    ...signersData,
                    {
                      name: '',
                      accountId: '',
                      accountKey: '',
                    },
                  ],
                });
              }
            }}
          >
            <PlusIcon />
            <Text ml={1}>Add new</Text>
          </Button>
        </Box>
        {
          showWarningMessage && (
            <Flex
              borderRadius={isScreenSmall ? 0 : 8}
              alignItems="center"
              borderY={isScreenSmall ? '1px solid rgba(0, 0, 0, 0.1)' : 'unset'}
              bg={isScreenSmall ? "white" : "primary"}
              color='warn'
              p={4}
              mx={isScreenSmall ? 0 : 54}
              gap={2}
              direction={isScreenSmall ? 'column' : 'row'}
            >
              <Flex alignItems="center" gap={2}>
                {!isScreenSmall && <InfoIcon />}
                <Text size="xs" textAlign={isScreenSmall ? 'center' : 'unset'}>
                  Your own address is not present in the signers list.
                  You won't be able to interact with the MultiSig when created.
                </Text>
              </Flex>
              <Button
                px={12}
                ml={isScreenSmall ? 'unset' : 4}
                fontSize={12}
                onClick={() => addAccountId(signersData, MultisigRoles.Creator, accountId, data)}
              >
                Add own address
              </Button>
            </Flex>
          )
        }
      </Flex>
    </Flex>
  );
};

export default SignersForm;
