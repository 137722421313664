import { NumberInput, Input } from "@chakra-ui/react";
import { getInputLabel } from "../hepers";
import { InputComponentProps } from "types/abi";
import { styles } from "../styles";

const NumberInputComponent = ({ value, placeholder, index, isDisabled, isInvalid, notEnoughBalance, setter }: InputComponentProps) => {
    const isVisibleLabel = !!value || value === 0
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'e' || event.key === '.') {
            event.preventDefault();
        }
    };

    return (
        <NumberInput>
            {getInputLabel(placeholder, isVisibleLabel, isInvalid, notEnoughBalance)}
            <Input
                isDisabled={isDisabled}
                isInvalid={(!!value && isInvalid) || notEnoughBalance}
                type='number'
                sx={styles.numberInputComponent}
                value={value ?? ''}
                placeholder={!value ? placeholder : undefined}
                onChange={(event) => setter(index, event.target.value)}
                onKeyDown={handleKeyDown}
            />
        </NumberInput>
    )
};

export default NumberInputComponent;
