import { Box, Text, Flex } from '@chakra-ui/react';
import { MobileMenu } from './components/MobileMenu';
import { ApplicationRoutes } from 'utils/routes';
import { useParams } from 'react-router-dom';

const SettingsPage = () => {
  const { walletId, multisigAccountId } = useParams();

  return (
    <Flex
      flex={1}
      flexDirection="column"
    >
      <MobileMenu
        pageName='Settings'
        backUrl={`${ApplicationRoutes.Dashboard}/${walletId}/account/${multisigAccountId}`}
      />
      <Box display="flex" alignItems="center" width="full">
        <Text size="2xl" variant="bodyBold">
          Settings
        </Text>
      </Box>
    </Flex>
  );
};

export default SettingsPage;
