import { Flex } from '@chakra-ui/react';
import { useCallback, useContext, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getMultisigAccountData } from '../../api/multisigAPI';
import { GlobalContext } from '../../providers/Global';
import { ApplicationRoutes } from '../../utils/routes';
import Menu from './components/Menu';
import { SCREEN_LG, SCREEN_MD } from 'utils/constants';
import { useMediaQuery } from 'react-responsive';

const Dashboard = () => {
  const { walletId } = useParams();
  const navigate = useNavigate();

  const {
    connection: { accountId },
    updateAccount,
  } = useContext(GlobalContext);

  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });
  const isScreenLarge = useMediaQuery({ query: `(max-width: ${SCREEN_LG})` });

  const fetchAccountData = useCallback(async () => {
    if (walletId) {
      const data = await getMultisigAccountData(walletId);
      if (data?.data) {
        updateAccount(data.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId]);

  useEffect(() => {
    if (!accountId) {
      navigate(ApplicationRoutes.Base);
    }
    fetchAccountData();
  }, [accountId, fetchAccountData, navigate]);

  return (
    <Flex
      py={isScreenMedium ? 0 : 87}
      w="full"
      direction={isScreenLarge ? 'column' : 'row'}
    >
      {
        !isScreenLarge && (
          <Menu />
        )
      }
      <Outlet />
    </Flex>
  );
};

export default Dashboard;
