import { Flex } from '@chakra-ui/react';
import TransactionList from './components/TransactionList';
import { MobileMenu } from './components/MobileMenu';
import { useParams } from 'react-router-dom';
import { ApplicationRoutes } from 'utils/routes';

const TransactionsPage = () => {
  const { walletId, multisigAccountId } = useParams();

  return (
    <Flex
      flex={1}
      flexDirection="column"
    >
      <MobileMenu
        pageName='Transactions'
        backUrl={`${ApplicationRoutes.Dashboard}/${walletId}/account/${multisigAccountId}`}
      />
      <TransactionList />
    </Flex>
  );
};

export default TransactionsPage;
