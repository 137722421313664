import { Flex, Box, Text, Divider } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MultisigLogo } from 'assets/multisig-logo.svg';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import { ApplicationRoutes } from '../../../utils/routes';
import WalletsWidget from '../../Header/WalletsWidget';
import NetworksWidget from '../../Header/NetworksWidget';
import { GlobalContext } from 'providers/Global';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useVisibility } from 'hooks/useVisibility';
import { ModalMenu } from './ModalMenu';

export const Header = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { connectedNetwork } = useContext(GlobalContext);

  const [MenuModal, toggleMenuModal] = useVisibility(ModalMenu);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      px={{ base: 4, md: 8 }}
      py={3}
      gap={3}
      h={16}
    >
      <Box display="flex" alignItems="center" cursor="pointer" gap={2} onClick={() => navigate(ApplicationRoutes.Base)}>
        <MultisigLogo />
        <Text size="2xl" variant="bodyBold">
          Hedera MultiSig
        </Text>
      </Box>
      {
        !isTabletOrMobile && (
          <Box display="flex" alignItems="center" gap={2}>
            <Flex
              sx={{
                height: '100%',
                opacity: !!connectedNetwork ? 1 : 0,
                visibility: !!connectedNetwork ? 'visible' : 'hidden',
                transition: "opacity 0.5s ease-in-out"
              }}
            >
              <Divider orientation="vertical" />
              <WalletsWidget />
            </Flex>
            <Divider orientation="vertical" />
            <NetworksWidget />
          </Box>
        )
      }
      {
        isTabletOrMobile && (
          <>
            <Flex
              display="flex"
              alignItems="center"
              cursor="pointer"
              w={6}
              h={6}
              mr={1}
              onClick={toggleMenuModal}
            >
              <MenuIcon />
            </Flex>
            <MenuModal onClose={toggleMenuModal} />
          </>
        )
      }
    </Flex>
  );
};

Header.displayName = 'Header';
