import {
  type ComponentType,
  useCallback,
  useMemo,
  useState,
} from 'react';

export type TVisibilityToggle = (e?: React.MouseEvent, openState?: boolean) => void;

export const useVisibility = <T extends object>(
  WrappedComponent: ComponentType<T>,
  initial?: boolean,
): [ComponentType<T>, (e?: React.MouseEvent) => void] => {
  const [isVisible, setIsVisible] = useState(!!initial);
  const toggleVisible = useCallback<TVisibilityToggle>((e, openState) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsVisible((state) => openState !== undefined ? openState : !state);
  }, []);
  const Component = useMemo(() => {
    return (props: T) => isVisible ? <WrappedComponent {...props} /> : null;
  }, [WrappedComponent, isVisible]);
  return [Component, toggleVisible];
};
