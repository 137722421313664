export const styles = {
    booleanInputComponent: (show: boolean) => (
        { opacity: show ? 1 : 0.5, fontWeight: 900, fontSize: 12 }
    ),
    numberInputComponent: {
        marginTop: 4,
        height: 12,
        borderRadius: 16
    },
    stringInputComponent: {
        marginTop: 4,
        height: 12,
        size: "sm",
        borderRadius: 16
    },
    formLabel: (isVisible: boolean, isInvalid?: boolean) => ({
        paddingLeft: 4,
        transition: 'all .4s linear',
        opacity: isVisible ? 1 : 0,
        fontSize: 10,
        textColor: isInvalid ? 'red' : 'secondary',
        marginBottom: -3.5
    }),
};
