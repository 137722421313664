import { FormLabel } from "@chakra-ui/react";
import { AbiFunctionInput, InputComponentProps } from "types/abi";
import { styles } from "./styles";

export const getInputLabel = (
    value: string,
    isVisible: boolean,
    isInvalid?: boolean,
    notEnoughBalance?: boolean
) => {
    const isInvalidLabel = isVisible && (isInvalid || notEnoughBalance);
    let invalidText = `Invalid ${value}`;
    if (notEnoughBalance) {
        invalidText = `Not enough balance`;
    }
    return <FormLabel sx={styles.formLabel(isVisible, isInvalidLabel)} children={isInvalidLabel ? invalidText : value} />
};

export const getInputProps = (abiProps: AbiFunctionInput): InputComponentProps => {
    const { selectedMethodInputs, index, input, setter, } = abiProps;
    const placeholder = `${input.name} (${selectedMethodInputs[index].type})`;
    const value = selectedMethodInputs[index].value;
    return {
        value,
        placeholder,
        index,
        setter
    }
};
