import { Flex, Box, Text, Button, Collapse, useDisclosure, Divider } from '@chakra-ui/react';
import { ReactComponent as ArrowDown } from 'assets/arrow-down-icon.svg';
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import { ReactComponent as PersonIcon } from 'assets/person-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/success-icon.svg';
import { ReactComponent as SignedIcon } from 'assets/signed-icon.svg';
import { ReactComponent as PendingIcon } from 'assets/pending-icon.svg';
import copyToClipboard from 'copy-to-clipboard';
import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { signCheck } from '../../../api/multisigAPI';
import { useToast } from '../../../hooks/useToast';
import { GlobalContext } from '../../../providers/Global';
import { TransactionStatusEnum, TransactionType } from '../../../types/transactions';
import { Signer } from 'types/account';
import { SCREEN_MD, SCREEN_SM } from 'utils/constants';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';

const styles = {
  status: (isMobile: boolean, color?: string) => {
    if (isMobile && color) {
      return {
        position: 'relative',
        '&:before': {
          content: "''",
          position: "absolute",
          inset: 0,
          right: "unset",
          width: '2px',
          backgroundColor: color,
        }
      }
    }
    return {}
  }
};

interface OwnProps {
  transaction: TransactionType;
}

const TransactionItem = ({ transaction }: OwnProps) => {
  const {
    _id,
    type,
    createdAt,
    expires_at,
    signatures,
    signatures_count,
    signed,
    status,
    schedule_id,
    params: { amount, toAccountId },
  } = transaction;
  const { walletId } = useParams();
  const { isOpen, onToggle } = useDisclosure();
  const {
    connection: { accountId, hashconnectConnectorInstance },
    currentAccount,
    connectedNetwork
  } = useContext(GlobalContext);
  const { showToast } = useToast();

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });
  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });

  const sign = async () => {
    try {
      const signedTx = await hashconnectConnectorInstance.signSendTokensTransaction(
        schedule_id,
        accountId,
        connectedNetwork!
      );
      if (walletId && signedTx) {
        await signCheck(walletId, signedTx.transactionId?.toString(), _id, accountId);
        showToast('Successfully signed', 'success', undefined, 'top-right');
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const statusColor = useMemo(() => {
    switch (status) {
      case TransactionStatusEnum.Success:
      case TransactionStatusEnum.Completed:
        return '#1EC843'
      case TransactionStatusEnum.Expired:
      case TransactionStatusEnum.Failed:
      case TransactionStatusEnum.Rejected:
        return '#E53E3E'
      case TransactionStatusEnum.Waiting:
        return '#ED8936'
      default:
        return
    }
  }, [status]);

  const dateFormat = useCallback((date: Date) => {
    return isScreenSmall
      ? format(date, 'dd MMM yyyy')
      : new Date(date).toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })

  }, [isScreenSmall]);

  return (
    <>
      <Flex
        h={14}
        p={11}
        borderTopRadius={isScreenSmall ? 0 : 8}
        onClick={onToggle}
        cursor={'pointer'}
        alignItems="center"
        borderY="1px solid #F6F6F6"
        sx={styles.status(isScreenSmall, statusColor)}
      >
        {
          !isScreenSmall && (
            <Box flex={1}>
              <Text size="xs" isTruncated>
                {_id.slice(0, 3)}...{_id.slice(_id.length - 3, _id.length)}
              </Text>
            </Box>
          )
        }
        <Box flex={2}>
          <Text size="xs" isTruncated>{type}</Text>
        </Box>
        <Box flex={2}>
          <Text size="xs" isTruncated>{amount} HBAR</Text>
        </Box>
        <Box flex={2}>
          <Text size="xs" isTruncated>
            {dateFormat(new Date(expires_at * 1000))}
          </Text>
        </Box>
        {
          !isScreenSmall && (
            <Box flex={1}>
              <Text size="xs" ml={3}>
                {signatures_count} of {currentAccount?.keys?.length}
              </Text>
            </Box>
          )
        }

        {
          !isScreenSmall && (
            <Box minW={155} flex={1} display="flex" alignItems="center">
              <Text
                mr={1}
                w={78}
                h="24px"
                ml="auto"
                size="xs"
                color="white"
                align="center"
                borderRadius={6}
                variant="bodyBold"
                bg={statusColor}
                lineHeight="24px"
              >
                {TransactionStatusEnum[status]}
              </Text>
              <ArrowDown />
            </Box>
          )
        }
      </Flex>
      <Collapse in={isOpen} transition={{ exit: { delay: 0.1 }, enter: { duration: 0.2 } }}>
        <Flex
          direction="column"
          px={4}
          py={6}
          borderRadius={8}
          gap={8}
          bg="primary"
        >
          <Flex
            gap={4}
            direction={isScreenSmall ? 'column' : 'row'}
          >
            <Flex
              flex={1}
              flexDirection="column"
              gap={4}
            >
              <Flex
                gap={4}
                flexDirection={isScreenMedium ? 'column' : 'row'}
                direction="column"
              >
                <Flex flex={1} direction="column" gap={1}>
                  <Text size="xs">Send</Text>
                  <Box p={4} bg="white" borderRadius={8}>
                    <Text size="xs">Token</Text>
                    <Text size="sm" variant="bodyBold">
                      {amount} HBAR
                    </Text>
                  </Box>
                </Flex>
                <Flex flex={1} direction="column" gap={1}>
                  <Text size="xs">To</Text>
                  <Box p={4} bg="white" borderRadius={8}>
                    <Text size="xs">Wallet Address</Text>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text size="sm" variant="bodyBold">
                        {toAccountId}
                      </Text>
                      <CopyIcon
                        cursor="pointer"
                        onClick={() => {
                          copyToClipboard(toAccountId);
                          showToast('Copied to clipboard', 'success', undefined, 'top');
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                gap={1}
                w={isScreenSmall ? 'full' : 'calc(50% - 8px)'}
              >
                <Text size="xs">Fee</Text>
                <Box p={4} bg="white" borderRadius={8}>
                  <Text size="xs">Gas Fee</Text>
                  <Text size="sm" variant="bodyBold">
                    0.0003
                  </Text>
                </Box>
              </Flex>
            </Flex>

            <Divider
              h="inherit"
              border="1px solid rgba(0, 0, 0, 0.16)"
              orientation={isScreenSmall ? 'horizontal' : 'vertical'}
              mt={isScreenSmall ? 2 : 0}
            />

            <Flex
              direction="column"
              gap={1}
            >
              <Text size="xs">Progress</Text>
              <Box
                p={isScreenSmall ? 4 : 6}
                display="flex"
                h="224px"
                bg="white"
                borderRadius={8}
                gap={4}
              >
                <Flex direction="column" h="full" alignItems="center">
                  <Box
                    w={4}
                    h={4}
                    bg="white"
                    borderRadius={50}
                    border="2px solid rgba(0, 0, 0, 0.36)"
                  ></Box>
                  <Divider h="19px" orientation="vertical" />
                  <Box
                    w="14px"
                    h="14px"
                    bg="white"
                    borderRadius={50}
                    border="2px solid rgba(0, 0, 0, 0.36)"
                  ></Box>
                  <Divider h="104px" orientation="vertical" />
                  <Box w={4} h={4} bg={statusColor} borderRadius={50}></Box>
                </Flex>
                <Flex flex={2} direction="column">
                  <Box display="flex" alignItems="center" gap={1}>
                    <Text size="xs" variant="bodyBold">
                      Created
                    </Text>
                    <Text
                      size="xs"
                      whiteSpace="nowrap"
                      sx={{ ...(isScreenSmall ? { fontSize: '11px !important' } : {}) }}
                    >
                      {new Date(createdAt).toLocaleString(undefined, {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        hour12: false,
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                    </Text>
                  </Box>
                  <Box mt={4} display="flex" alignItems="center" gap={2}>
                    <Text size="xs" variant="bodyBold">
                      Signed
                    </Text>
                    <PersonIcon />
                    <Text size="xs">
                      {signatures_count} out of {currentAccount?.keys?.length}
                    </Text>
                  </Box>
                  <Box
                    p={4}
                    pl={isScreenSmall ? 3 : 4}
                    pr={0}
                    mb={4}
                    gap={2}
                    minH="84px"
                    maxH={26}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    overflow="hidden"
                    overflowY={
                      currentAccount.keys && currentAccount.keys?.length > 3 ? 'scroll' : 'hidden'
                    }
                  >
                    {currentAccount?.keys?.map((key: Signer) => {
                      return (
                        <Flex key={key.accountId} alignItems="center" gap={1}>
                          <Text size="xs" color="blackAlpha.700">
                            {key.accountId}
                          </Text>
                          {
                            signatures?.findIndex((signature: { key: string }) => signature.key === key.key) !== -1
                              ? (
                                <>
                                  <Text whiteSpace="nowrap" size="xs" color="blackAlpha.700">
                                    - Signed
                                  </Text>
                                  <Flex alignItems="center" justifyItems="center" ml="auto">
                                    <SignedIcon />
                                  </Flex>
                                </>
                              )
                              : (
                                <>
                                  <Text size="xs" color="blackAlpha.700">
                                    - Pending
                                  </Text>
                                  <Flex alignItems="center" justifyItems="center" ml="auto">
                                    <PendingIcon />
                                  </Flex>
                                </>
                              )
                          }
                        </Flex>
                      );
                    })}
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    {status === TransactionStatusEnum.Waiting && (
                      <Text size="xs" variant="bodyBold">
                        Pending
                      </Text>
                    )}
                    {status === TransactionStatusEnum.Expired && (
                      <Text size="xs" variant="bodyBold">
                        Expired
                      </Text>
                    )}
                    {
                      (status === TransactionStatusEnum.Success || status === TransactionStatusEnum.Completed) && (
                        <Text size="xs" variant="bodyBold">
                          Executed
                        </Text>
                      )
                    }
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Flex>

          <Flex justifyContent="flex-end">
            {signed ? (
              <Flex align="center" gap={2} mr={3}>
                <SuccessIcon width={20} height={20} />
                <Text size="xs" variant="bodyBold">
                  You've signed this transaction
                </Text>
              </Flex>
            ) : (
              <Button
                w={160}
                onClick={sign}
                borderRadius={6}
                variant="primary"
                isDisabled={status !== TransactionStatusEnum.Waiting}
              >
                Sign
              </Button>
            )}
          </Flex>
        </Flex>
      </Collapse>
    </>
  );
};

export default TransactionItem;
