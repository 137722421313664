import { Flex, Text } from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { ReactComponent as ArrowDownBlack } from 'assets/arrow-down-black-icon.svg';
import { Fragment, useContext } from "react";
import { GlobalContext } from "providers/Global";
import { ModalType } from "utils/constants";
import { styles } from "./styles";

export const MultiSigWarning = () => {
    return (
        < Fragment >
            <Flex borderRadius={8} alignItems="center" bg="primary" p={4} gap={2}>
                <InfoIcon />
                <Text size="xs">
                    Currently each multi-signature transaction is valid for a period of 30 minutes.
                </Text>
            </Flex >
        </Fragment >
    );
};

export const ModalBackBtn = ({ pathTo }: { pathTo?: ModalType }) => {
    const { openModal } = useContext(GlobalContext);
    return <ArrowDownBlack
        style={styles.ModalBackBtn}
        onClick={() => (openModal(pathTo || ModalType.CreateTransactionModal))}
    />
};
