import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createSendTransaction } from '../../api/multisigAPI';
import { GlobalContext } from '../../providers/Global';
import { ModalType, SCREEN_MD } from '../../utils/constants';
import ConfirmTransactionModal from './ConfirmTransactionModal';
import SuccessModal from './SuccessModal';
import { ModalBackBtn, MultiSigWarning } from './helpers';
import NumberInputComponent from 'components/ABI/components/NumberInputComponent';
import StringInputComponent from 'components/ABI/components/StringInputComponent';
import { TxInfo } from 'types/transactions';
import { useToast } from 'hooks/useToast';
import { useMediaQuery } from 'react-responsive';
import { isValidAccount } from 'utils/general';

const SendTokensModal = () => {
  const {
    modals: {
      [`${ModalType.SendTokensModal}`]: { open },
    },
    balance,
    connection: { accountId, hashconnectConnectorInstance },
    connectedNetwork,
    openModal,
    closeModal,
  } = useContext(GlobalContext);

  const { walletId, multisigAccountId } = useParams();
  const { generalSuccess, generalError } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sendTokensData, setSendTokensData] = useState({
    address: '',
    amount: '',
  });

  const isScreenMedium = useMediaQuery({ query: `(max-width: ${SCREEN_MD})` });

  const save = async () => {
    try {
      setIsLoading(true);

      const tx: TxInfo = {
        from: multisigAccountId || '',
        to: sendTokensData.address,
        amount: Number(sendTokensData.amount)
      }

      const sendTokenResponse = await hashconnectConnectorInstance
        .createSendTokenScheduledTransaction(tx, connectedNetwork!, accountId);

      await createSendTransaction(tx, sendTokenResponse, walletId!);
      generalSuccess()
      setTimeout(() => {
        window.location.reload();
      }, 500)

    } catch (error: any) {
      console.error(error);
      generalError()

    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setIsSuccessOpen(false);
    closeModal(ModalType.SendTokensModal);
  };

  const handleDataChange = (name: string, value: any) => {
    setSendTokensData({
      ...sendTokensData,
      [name]: value,
    })
  };

  const isNextButtonDisabled = useMemo(() => {
    return !isValidAccount(sendTokensData.address) || !sendTokensData.amount || Number(sendTokensData.amount) > balance;
  }, [sendTokensData.address, sendTokensData.amount, balance]);

  return (
    <>
      <ConfirmTransactionModal
        data={{
          isLoading,
          create: save,
          handleClose: () => {
            handleClose();
            setShowConfirm(false);
          },
          handleBack: () => {
            openModal(ModalType.SendTokensModal)
            setShowConfirm(false)
          },
          amount: sendTokensData.amount,
          address: sendTokensData.address,
          open: showConfirm,
        }}
      />
      <SuccessModal
        data={{
          handleClose,
          amount: 1.5,
          address: '1Lbcfr7sAHT...MTkV',
          isOpen: isSuccessOpen,
        }}
      />
      <Modal onClose={handleClose} isOpen={open} isCentered>
        <ModalOverlay />
        <ModalContent
          maxWidth="586px"
          w="full"
          mx={isScreenMedium ? 4 : 0}
        >
          <ModalHeader pt={6} px={6} pb={0}>
            <ModalBackBtn />
            <Flex alignItems="center" flexDirection="column">
              <Text mt={10} size="2xl" variant="bodyBold">
                Send Tokens
              </Text>
            </Flex>
            <ModalCloseButton
              mt={2}
              mr={2}
              color="#565656"
              _hover={{ border: 'none' }}
              _focusVisible={{ outline: 'none' }}
            />
          </ModalHeader>
          <ModalBody pb={14} pt={4} px={8} maxW={{ base: '420px', md: '520px' }} mx="auto">
            <Flex alignItems="center" flexDirection="column" gap={6}>
              <Box width="full">
                <StringInputComponent
                  value={sendTokensData.address}
                  placeholder="Recipient address"
                  index="address"
                  isInvalid={!isValidAccount(sendTokensData.address)}
                  setter={handleDataChange}
                />
              </Box>
              <Box width="full">
                <NumberInputComponent
                  value={sendTokensData.amount}
                  placeholder="HBAR"
                  index="amount"
                  notEnoughBalance={Number(sendTokensData.amount) > balance}
                  setter={handleDataChange}
                />
              </Box>
              <MultiSigWarning />
              <Button
                w={204}
                borderRadius={6}
                variant="primary"
                isDisabled={isNextButtonDisabled}
                onClick={() => {
                setShowConfirm(true)
                handleClose();
              }}>
                Next
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendTokensModal;
