import axios from 'axios';
import {
  hederaRestApiUrl,
  NetworkType,
  REACT_APP_BACKEND_URL,
} from '../utils/constants';

export const getAccountData = async (accountId: string, network: NetworkType) => {
  const {
    data: {
      key: { key },
      balance: { balance },
    },
  } = await axios.get(
    `${hederaRestApiUrl[network]}/api/v1/accounts/${accountId}`,
  );

  return {
    key,
    balance: balance / 100000000,
  };
};

export const getAccountDataByNetwork = async (accountId: string, network: NetworkType) => {
  const { data } = await axios.get(`${hederaRestApiUrl[network]}/api/v1/accounts/${accountId}`);
  return data;
};

export const authenticate = async (accountId: string, network: NetworkType) => {
  const { key } = await getAccountData(accountId, network);

  const {
    data: { access_token },
  } = await axios.post(`${REACT_APP_BACKEND_URL}/auth`, {
    accountId,
    key: `302a300506032b6570032100${key}`,
    network
  })!;

  localStorage.setItem('token', access_token);
  localStorage.setItem('accountId', accountId);
  window.dispatchEvent(new Event('token'));

  return access_token;
};
