export enum TransactionStatusEnum {
  Waiting,
  Success,
  Rejected,
  Expired,
  Failed,
  Completed
}

export enum TransactionListLabels {
  ID = 'ID',
  ACTION = 'Action',
  AMOUNT = 'Amount',
  EXPIRES = 'Expires',
  THRESHOLD = 'Threshold',
  STATUS = 'Status',
}

export interface TransactionType {
  _id: string;
  type: string;
  status: number;
  signed: boolean;
  createdAt: string;
  expires_at: number;
  schedule_id: string;
  required_signatures: number;
  signatures_count: number;
  signatures: [
    {
      transactionId: string;
      key: string;
    },
  ];
  params: {
    amount: number;
    description: string;
    toAccountId: string;
  };
}

export interface CreateTransaction {
  contractId: string;
  contractMethod: string;
  params: [
    {
      type: string;
      value: string;
    },
  ];
}

export interface TxInfo {
  from: string;
  to: string;
  amount: number;
}
