import { ComponentStyleConfig } from '@chakra-ui/react';

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 16,
    fontWeight: 600,
  },
  sizes: {
    '2xl': {
      fontSize: 24,
    },
    xl: {
      fontSize: 18,
    },
    lg: {
      fontSize: 16,
    },
    md: {
      fontSize: 14,
    },
    sm: {
      fontSize: 12,
    },
    xs: {
      fontSize: 10,
    },
  },
  variants: {
    primary: {
      color: 'white',
      bg: 'blackAlpha.900',
      _hover: {
        bg: 'blackAlpha.500',
      },
      _disabled: {
        _hover: {
          bg: "blackAlpha.900 !important",
        },
      },
    },
    secondary: {
      bg: 'white',
      color: 'black',
      border: '1px solid black',
      _hover: {
        bg: 'blackAlpha.200',
      },
    },
    ghost: {
      bg: 'primary',
      color: 'black',
      borderRadius: 12,
      _hover: {
        bg: 'blackAlpha.200',
      },
      _disabled: {
        _hover: {
          bg: "black !important",
        },
      }
    },
  },
};

export default Button;
