export const styles = {
    rotatingArrow: (rotate: boolean) => ({
        transform: rotate ? 'rotateX(180deg)' : 'rotateX(0deg)', transition: 'transform 0.3s'
    }),
    popoverItem: {
        maxH: 20,
        p: 1.5,
        gap: 2,
        width: "full",
        display: "flex",
        cursor: "pointer",
        borderRadius: 8,
        alignItems: "center",
        _hover: {
            background: 'rgba(0, 0, 0, 0.08)',
        }
    }
};
