import { Box, Text, Flex, Button } from '@chakra-ui/react';
import { useContext } from 'react';
import { GlobalContext } from '../../providers/Global';
import { MobileMenu } from './components/MobileMenu';
import { useParams } from 'react-router-dom';
import { ApplicationRoutes } from 'utils/routes';

const AssetsPage = () => {
  const { walletId, multisigAccountId } = useParams();
  const { currentAccount } = useContext(GlobalContext);

  return (
    <Flex
      flex={1}
      flexDirection="column"
    >
      <MobileMenu
        pageName='Assets'
        backUrl={`${ApplicationRoutes.Dashboard}/${walletId}/account/${multisigAccountId}`}
      />
      <Flex
        p={6}
        bg="white"
        height="100%"
        borderRadius={16}
        flexDirection="column"
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      >
        <Flex width="full" alignItems="center" h={10} borderRadius={6} p={4} bg="primary">
          <Box flex={1}>
            <Text size="xs">Asset</Text>
          </Box>
          <Box>
            <Text size="xs">Amount</Text>
          </Box>
        </Flex>
        <Box display="flex" width="full" flexDirection="column">
          <Flex
            h={14}
            p={4}
            width="full"
            borderRadius={8}
            alignItems="center"
            borderY="1px solid #F6F6F6"
          >
            <Box flex={1}>
              <Button bg="#E6E6E6" color="black" borderRadius={6} w={14} h={6} fontSize={12}>
                HBAR
              </Button>
            </Box>
            <Box>
              <Text size="xs">
                {Number(currentAccount.balances?.hbars.replace(/[^0-9.]/g, '')).toFixed(2)}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AssetsPage;
