import { FormControl, Input } from "@chakra-ui/react";
import { getInputLabel } from "../hepers";
import { InputComponentProps } from "types/abi";
import { styles } from "../styles";

const StringInputComponent = ({ value, placeholder, index, isDisabled, isInvalid, setter }: InputComponentProps) => {
    return (
        <FormControl>
            {getInputLabel(placeholder, !!value, isInvalid)}
            <Input
                isDisabled={isDisabled}
                isInvalid={!!value && isInvalid}
                sx={styles.stringInputComponent}
                type='text'
                value={value ?? ''}
                placeholder={placeholder}
                onChange={(event) => setter(index, event.target.value)}
            />
        </FormControl>
    )
};

export default StringInputComponent;
