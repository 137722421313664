/// camelCase -> Camel case
export const normalizedCamelCaseString = (str: string) => {
    return str
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace(str[0], str[0].toUpperCase());
};

export const isValidAccount = (input: string): boolean => {
    const regex = /^0\.0\.\d+$/;
    return regex.test(input);
}
