import { Flex, Box, Text, Grid, GridItem } from '@chakra-ui/react';
import { CreateAccount, Signer } from '../../types/account';
import copyToClipboard from 'copy-to-clipboard';
import { ReactComponent as PenIconGreen } from 'assets/pen-icon-green.svg';
import { ReactComponent as CopyIconSmall } from 'assets/copy-icon-small.svg';
import { useToast } from 'hooks/useToast';
import { MultisigRoles, SCREEN_SM } from 'utils/constants';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'providers/Global';
import { useMediaQuery } from 'react-responsive';

interface OwnProps {
  data: CreateAccount;
  duplicateAddress: boolean;
}

const SummaryForm = ({ data, duplicateAddress }: OwnProps) => {
  const refTimeout = useRef<NodeJS.Timeout>();

  const refAddress = useRef<HTMLDivElement>(null);
  const refNetwork = useRef<HTMLDivElement>(null);
  const refSigners = useRef<HTMLDivElement>(null);
  const refContainerGrid = useRef<HTMLDivElement>(null);

  const [accounts, setAccounts] = useState<Signer[]>([])

  const { name, network } = data;
  const { showToast } = useToast();
  const { connection: { accountId: ownID } } = useContext(GlobalContext);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  useEffect(() => {
    if (data.accounts && refSigners.current && refContainerGrid.current) {
      const accounts = data.accounts;
      let maxH = ((refAddress.current?.clientHeight || 0) + (refNetwork.current?.clientHeight || 0));

      if (isScreenSmall) {
        const rect1 = refContainerGrid.current.getBoundingClientRect();
        const rect2 = refSigners.current.getBoundingClientRect();
        maxH = rect2.top - rect1.top;
      }

      refSigners.current.style.height = `calc(${maxH}px + var(--chakra-space-4))`;

      refTimeout.current = setTimeout(() => {
        setAccounts(accounts);
      }, 400);

    }
  }, [data.accounts, isScreenSmall])

  return (
    <Flex
      w="full"
      direction="column"
      gap={6}
      bg={!isScreenSmall ? "white" : ""}
      boxShadow={!isScreenSmall ? "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)" : ""}
      borderRadius={16}
      pb={isScreenSmall ? 0 : "30px"}
    >
      <Flex
        p={!isScreenSmall ? 54 : "24px"}
        pb={0}
        direction="column"
        gap={2}
      >
        <Text
          position="relative"
          display="flex"
          pl={isScreenSmall ? "0" : "80px"}
          size="lg"
          variant="bodyBold"
        >
          <Box
            as="span"
            position="absolute"
            top={0}
            left={0}
            display={isScreenSmall ? "none" : "block"}
          >
            <PenIconGreen />
          </Box>
          Summary
        </Text>
        <Text
          pl={isScreenSmall ? "0" : "80px"}
          size="sm"
          color="blackAlpha.600"
        >
          Here is a summary of the wallet you are about to create.
          {
            !isScreenSmall && (
              <>
                Please validate the information and make sure you have everything properly set.
                You can go back and edit any of the steps without loosing your input.
              </>
            )
          }
        </Text>
      </Flex>

      <Flex
        flex={1}
        gap={4}
        px={isScreenSmall ? '24px' : 54}
      >
        <Grid
          ref={refContainerGrid}
          flex={1}
          gap={4}
          templateColumns={"repeat(2, 1fr)"}
          whiteSpace="nowrap"
          alignContent="flex-start"
        >
          <GridItem
            colSpan={1}
            p={4}
            flexDirection="column"
            gap={2}
            bg="primary"
            borderRadius={8}
            overflow="hidden"
          >
            <Text size="sm">Wallet Name</Text>
            <Text size="lg" variant="bodyBold" isTruncated>
                {name || 'Wallet name'}
            </Text>
          </GridItem>
          <GridItem
            colSpan={1}
            p={4}
            flexDirection="column"
            gap={2}
            bg="primary"
            borderRadius={8}
            overflow="hidden"
          >
            <Text size="sm">Threshold</Text>
            <Text size="lg" variant="bodyBold" isTruncated>
              {data.threshold} out of {data.accounts?.length}
            </Text>
          </GridItem>
          <GridItem
            ref={refAddress}
            colSpan={1}
            p={4}
            flexDirection="column"
            gap={2}
            bg="primary"
            borderRadius={8}
            overflow="hidden"
          >
            <Text size="sm">Wallet Address</Text>
            <Text size="lg" variant="bodyBold" isTruncated>
              {ownID}
            </Text>
          </GridItem>
          <GridItem
            rowSpan={2}
            colSpan={isScreenSmall ? 2 : 1}
            order={isScreenSmall ? 1 : "unset"}
            alignSelf="stretch"
            h="full"
          >
            <Flex
              ref={refSigners}
              gap={4}
              p={4}
              bg="primary"
              borderRadius={8}
              flexDirection="column"
              flex={1}
            >
              <Text size="sm">
                Signers
              </Text>

              <Flex direction="column" h="full" overflow="scroll" gap={2} pr={6}>
                {
                  accounts?.map((signer: Signer) => {
                    const { name, accountId } = signer;
                    return (
                      <Flex gap={4} key={name} justifyContent="space-between" whiteSpace="nowrap">
                        <Text flex={1} size="xs">
                          {(accountId === ownID) ? MultisigRoles.OwnAddress : name}
                        </Text>
                        <Text size="xs">{accountId}</Text>
                        {accountId && (
                          <CopyIconSmall
                            cursor="pointer"
                            onClick={() => {
                              copyToClipboard(accountId);
                              showToast('Copied to clipboard', 'success', undefined, 'top');
                            }}
                          />
                        )}
                      </Flex>
                    );
                  })
                }
              </Flex>
            </Flex>
            {duplicateAddress && (
              <Text size="xs" color="red" mt={1}>
                Duplicate address
              </Text>
            )}
          </GridItem>
          <GridItem
            ref={refNetwork}
            colSpan={1}
            p={4}
            flexDirection="column"
            gap={2}
            bg="primary"
            borderRadius={8}
          >
            <Text size="sm">Network</Text>
            <Text size="lg" variant="bodyBold" textTransform="capitalize">
              {network}
            </Text>
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default SummaryForm;
