import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { GlobalContext } from '../../providers/Global';
import { ModalType } from '../../utils/constants';
import { CreateTokenType, TokenFields } from 'types/token';
import { normalizedCamelCaseString } from 'utils/general';
import { InputComponentProps } from 'types/abi';
import StringInputComponent from 'components/ABI/components/StringInputComponent';
import NumberInputComponent from 'components/ABI/components/NumberInputComponent';
import { createToken } from 'api/multisigAPI';
import { ModalBackBtn, MultiSigWarning } from './helpers';

const defaultTokenData: CreateTokenType = {
    name: '',
    symbol: '',
    decimals: 8,
    initialSupply: 0
};

const CreateTokenModal = () => {
    const {
        modals: {
            [`${ModalType.CreateTokenModal}`]: { open },
        },
        closeModal,
    } = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [readyToCreate, setReadyToCreate] = useState<boolean>(false);
    const [tokenData, setTokenData] = useState<CreateTokenType>(defaultTokenData);
    const { generalSuccess, generalError } = useToast();
    const { walletId } = useParams();

    const handleCreate = async () => {
        try {
            setIsLoading(true);
            await createToken(walletId || '', tokenData);
            generalSuccess();
            window.location.reload();
        } catch (err: unknown) {
            generalError();
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setTokenData(defaultTokenData);
        closeModal(ModalType.CreateTokenModal);
    };

    const handleDataChange = (key: keyof CreateTokenType, value: any) => {
        setTokenData({
            ...tokenData,
            [key]: value
        });
    };

    const getTokenInput = (fieldName: TokenFields) => {
        const isInvalid = handleFieldIsInvalid(fieldName);
        const inputProps: InputComponentProps = {
            value: tokenData[fieldName] || '',
            placeholder: normalizedCamelCaseString(fieldName),
            index: fieldName,
            isInvalid,
            setter: handleDataChange
        }

        switch (fieldName) {
            case TokenFields.initialSupply:
            case TokenFields.decimals:
                return NumberInputComponent(inputProps);
            default:
                return StringInputComponent(inputProps);
        };
    };

    // Validation rules taken from here:
    // https://docs.hedera.com/hedera/sdks-and-apis/sdks/token-service/define-a-token
    // https://docs.hedera.com/hedera/sdks-and-apis/hedera-api/token-service/tokencreate
    const handleFieldIsInvalid = useCallback((fieldName: TokenFields): boolean => {
        let stringValue: string = tokenData[fieldName].toString();
        if (!stringValue || stringValue.includes('\u0000')) {
            return true
        }

        switch (fieldName) {
            case TokenFields.initialSupply:
                return Number(stringValue) <= 0 || Number(stringValue) >= Number.MAX_SAFE_INTEGER;

            case TokenFields.decimals:
                // Should we allow more than the usual 18 decimals?
                return Number(stringValue) < 0 || Number(stringValue) > 18;

            case TokenFields.name:
            case TokenFields.symbol:
                return stringValue.length > 100 || stringValue.length < 1;

            default:
                return true;
        };
    }, [tokenData]);

    const tokenDataIsInvalid = useCallback((tokenData: CreateTokenType) => {
        return !!Object.entries(tokenData).find(([key, _]) => handleFieldIsInvalid(key as TokenFields))
    }, [handleFieldIsInvalid]);

    useEffect(() => {
        setReadyToCreate(!tokenDataIsInvalid(tokenData));
    }, [tokenData, tokenDataIsInvalid]);

    return (
        <>
            <Modal onClose={handleClose} isOpen={open} isCentered>
                <ModalOverlay />
                <ModalContent minWidth="586px">
                    <ModalHeader pt={6} px={6} pb={0}>
                        <ModalBackBtn />
                        <Flex alignItems="center" flexDirection="column">
                            <Text mt={10} size="2xl" variant="bodyBold">
                                Create Token
                            </Text>
                        </Flex>
                        <ModalCloseButton
                            mt={2}
                            mr={2}
                            color="#565656"
                            _hover={{ border: 'none' }}
                            _focusVisible={{ outline: 'none' }}
                        />
                    </ModalHeader>
                    <ModalBody pb={14} pt={4} px={20}>
                        <Flex alignItems="center" flexDirection="column" gap={4}>
                            <Fragment>
                                <Flex gap={4} flexDirection="column" width={'100%'}>
                                    {Object.keys(TokenFields).map((field, idx) => {
                                        return (
                                            <Fragment key={field + idx}>
                                                {getTokenInput(field as TokenFields)}
                                            </Fragment>
                                        )
                                    })}
                                </Flex>
                            </Fragment>
                            <MultiSigWarning />
                            <Button
                                isDisabled={!readyToCreate}
                                w={203}
                                mt={5}
                                onClick={handleCreate}
                                variant="primary"
                                isLoading={isLoading}
                            >
                                Create
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateTokenModal;
