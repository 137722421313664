import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import type { ReactNode } from 'react';

interface PortalProps {
  id: string;
  children: ReactNode;
  withHeader?: boolean;
  disablePortal?: boolean;
}

export const ReactPortal = (props: PortalProps) => {
  const { id, children, withHeader, disablePortal = false } = props; // Default to false
  const containerRef = useRef<HTMLElement | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (disablePortal && !isMounted) {
      setIsMounted(true);
      return;
    }

    let container = document.getElementById(id);

    if (!container) {
      container = document.createElement('div');
      container.setAttribute('id', id);
      document.body.appendChild(container);
    }

    if (withHeader) {
      container.classList.add('withHeader');
    }

    containerRef.current = container;
    setIsMounted(true);

    return () => {
      if (container && container.parentNode === document.body) {
        document.body.removeChild(container);
      }
      containerRef.current = null;
    };
  }, [id, disablePortal, isMounted, withHeader]);

  if (disablePortal) {
    return <>{children}</>;
  }

  return isMounted && containerRef.current ? createPortal(children, containerRef.current) : null;
};

ReactPortal.displayName = 'ReactPortal';
