import {
  AlertStatus,
  Box,
  Link,
  ToastPosition,
  useToast as useChakraToast,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_SM } from 'utils/constants';

export const useToast = () => {
  const toast = useChakraToast();

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  const showToast = (msg: string, status: AlertStatus, id?: string, position?: ToastPosition) => {
    if (id && toast.isActive(id)) {
      return;
    }
    toast({
      id,
      status,
      duration: 3000,
      description: msg,
      position: position ?? 'top',
    });
  };

  const generalSuccess = () => {
    return showToast('Success', 'success', undefined, 'top-right');
  };

  const generalError = () => {
    return showToast('Error', 'error', undefined, 'top-right');
  };

  const showNoExtensionToast = () => {
    if (toast.isActive('missing-wallet')) {
      return;
    }

    toast({
      id: 'missing-wallet',
      duration: 5000,
      position: isScreenSmall ? 'bottom' : 'top-right',
      render: () => (
        <Box
          mt={20}
          maxW={440}
          w="full"
          p={4}
          bg="#B7F133"
          borderRadius={8}
        >
          It seems like you do not have HashPack installed.
          <br /> Please{' '}
          <Link
            color="black"
            textDecoration="underline"
            href="https://www.hashpack.app/download"
            cursor="pointer"
            isExternal
          >
            download and install it from here.
          </Link>
        </Box>
      ),
    });
  };

  return { showToast, showNoExtensionToast, generalSuccess, generalError };
};
