import { ColorHues, extendTheme } from '@chakra-ui/react';
import Text from './overrides/text';
import Button from './overrides/button';

const blackAlpha: ColorHues = {
  '50': 'rgba(0, 0, 0, 0.01)',
  '100': 'rgba(0, 0, 0, 0.1)',
  '200': 'rgba(0, 0, 0, 0.06)',
  '300': 'rgba(0, 0, 0, 0.12)',
  '400': 'rgba(0, 0, 0, 0.24)',
  '500': 'rgba(0, 0, 0, 0.36)',
  '600': 'rgba(0, 0, 0, 0.48)',
  '700': 'rgba(0, 0, 0, 0.60)',
  '800': 'rgba(0, 0, 0, 0.72)',
  '900': 'rgba(0, 0, 0, 0.84)',
};

export const whiteAlpha = {
  '50': 'rgba(255, 255, 255, 0.05)',
  '100': 'rgba(255, 255, 255, 0.1)',
  '150': 'rgba(255, 255, 255, 0.15)',
  '200': 'rgba(255, 255, 255, 0.2)',
  '300': 'rgba(255, 255, 255, 0.3)',
  '400': 'rgba(255, 255, 255, 0.4)',
  '500': 'rgba(255, 255, 255, 0.5)',
  '600': 'rgba(255, 255, 255, 0.6)',
  '700': 'rgba(255, 255, 255, 0.7)',
  '800': 'rgba(255, 255, 255, 0.8)',
  '900': 'rgba(255, 255, 255, 0.9)',
};

export const colors = {
  blackAlpha,
  whiteAlpha,
};

const theme = extendTheme({
  colors: {
    primary: '#F6F6F6',
    secondary: '#949494',
    warn: '#C40E3A'
  },
  components: {
    Text,
    Button,
  },
  fonts: {
    body: `'Sora', sans-serif`,
  },
});

export default theme;
