import { AbiFunctionInput, AbiMethodTypes } from 'types/abi';
import { getInputProps } from './hepers';
import BooleanInputComponent from './components/BooleanInputComponent';
import NumberInputComponent from './components/NumberInputComponent';
import StringInputComponent from './components/StringInputComponent';

// This might be ground for further developing types handling and validation
const AbiInput = ({ abiProps }: { abiProps: AbiFunctionInput }) => {
    const { selectedMethodInputs, index } = abiProps;
    const abiInputType = selectedMethodInputs[index].type;
    const inputProps = getInputProps(abiProps);

    switch (abiInputType) {
        case AbiMethodTypes.String:
        case AbiMethodTypes.Address:
            return StringInputComponent(inputProps);

        case AbiMethodTypes.Uint:
        case AbiMethodTypes.Uint256:
        case AbiMethodTypes.Int:
        case AbiMethodTypes.Int256:
            return NumberInputComponent(inputProps);

        case AbiMethodTypes.Bool:
            return BooleanInputComponent(inputProps);

        default:
            return StringInputComponent(inputProps);
    }
};

export default AbiInput;
