import { JdenticonConfig, toSvg } from "jdenticon"

// This, if needed, might be used to parameterize and limit avatars in terms of colors/shapes
const JD_CONFIG: JdenticonConfig = {}

export const toJDSvgUrl = (UID: string, size: number): string => {
    const svgString = toSvg(UID, size, JD_CONFIG)
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
};

// We really need more significant differences between UIDs,
// otherwise nearly identical icons might be generated
// For example the following walletIDs:
// 1. 656f00d9a78302e4417a74c8
// 2. 656f08dda78302e4417a76c0
// although different, would produce identical color/form output.
// Taking last majority of obvious differences seems to be handling this case well.
export const getAvatarUID = (UID: string | undefined): string => {
    const maxLength = 5
    if (!!UID && UID.length > maxLength) {
        return UID.slice(-maxLength)
    }
    return UID || ""
}
