import { Flex, Box, Text, Input } from '@chakra-ui/react';
import { ReactComponent as PenIconGreen } from 'assets/pen-icon-green.svg';
import { ReactComponent as HederaLogo } from 'assets/hedera-logo.svg';
import { SetStateAction, useState } from 'react';
import { CreateAccount } from '../../types/account';
import { handleNetworkColor } from '../../utils/color';
import { SCREEN_SM } from 'utils/constants';
import { useMediaQuery } from 'react-responsive';

interface OwnProps {
  data: CreateAccount;
  setData: (data: CreateAccount) => void;
}

const AccountForm = ({ data, setData }: OwnProps) => {
  const [accountName, setAccountName] = useState(data.name || '');

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });

  const handleChange = (event: { target: { value: SetStateAction<string> } }) =>
    setAccountName(event.target.value);

  return (
    <Flex
      direction="column"
      gap={6}
      bg={!isScreenSmall ? "white" : ""}
      boxShadow={!isScreenSmall ? "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)" : ""}
      borderRadius={16}
    >
      <Flex
        p={!isScreenSmall ? 54 : "24px"}
        pb={0}
        direction="column"
        gap={2}
      >
        <Text
          position="relative"
          display="flex"
          pl={isScreenSmall ? "0" : "80px"}
          size="lg"
          variant="bodyBold"
        >
          <Box
            as="span"
            position="absolute"
            top={0}
            left={0}
            display={isScreenSmall ? "none" : "block"}
          >
            <PenIconGreen />
          </Box>
          Personalise
        </Text>
        <Text
          pl={isScreenSmall ? "0" : "80px"}
          size="sm"
          color="blackAlpha.600"
        >
          Choose a name for your Multi-signature wallet. This name will be visible to all signers who are added in the next step.
        </Text>
      </Flex>

      <Flex
        gap={4}
        px={isScreenSmall ? '24px' : 54}
        direction={isScreenSmall ? 'column' : 'row'}
      >
        <Box flex="2">
          <Input
            size="lg"
            borderRadius={16}
            value={accountName}
            onChange={event => {
              const value = event?.target.value;
              setData({
                ...data,
                name: value,
              });
              handleChange(event);
            }}
            placeholder="Enter name of multisig"
          />
        </Box>
        <Box
          p={3}
          flex="1"
          h="48px"
          minWidth={242}
          borderRadius={16}
          border="1px solid rgba(0, 0, 0, 0.24)"
        >
          <Box gap={2} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <HederaLogo />
            <Text size="sm" textTransform="capitalize" variant="bodyBold" color={handleNetworkColor(data.network!)}>
              {data.network}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AccountForm;
