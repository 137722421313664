import {
  Box,
  Flex,
  Text,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { ReactComponent as ArrowDownBlack } from 'assets/arrow-down-black-icon.svg';
import { ReactComponent as HashpackLogo } from 'assets/hashpack-wallet-logo.svg';
import { useContext, useRef } from 'react';
import { GlobalContext } from '../../providers/Global';
import { SupportedWallets } from '../../utils/constants';
import { ReactComponent as GreenWalletIcon } from 'assets/wallet-icon-green.svg';
import { useToast } from 'hooks/useToast';
import { styles } from './styles';

type TPopoverItems = {
  logo: JSX.Element;
  text: string;
  clickHandler: () => void;
  isDisabled: boolean;
}

const PopoverItems = () => {

  const { connection: { connectWallet, hashconnectConnectorInstance } } = useContext(GlobalContext);
  const { onClose } = useDisclosure();
  const { showNoExtensionToast } = useToast();

  const handleWalletConnection = async () => {
    onClose();
    if (hashconnectConnectorInstance.extensionInstalled) {
      connectWallet();
    } else {
      showNoExtensionToast();
    }
  };

  const popoverItems: TPopoverItems[] = [
    {
      text: SupportedWallets.Hashpack,
      logo: <HashpackLogo style={{ maxHeight: '40px' }} />,
      clickHandler: handleWalletConnection,
      isDisabled: false
    },
  ]

  return (
    <Flex flexDirection="column">
      {
        popoverItems.map((item, idx) => {
          if (!item.isDisabled) {
            return (
              <Box
                key={`wallet-widget-x${idx}`}
                sx={styles.popoverItem}
                onClick={item.clickHandler}
              >
                {item.logo}
                <Text size="sm">
                  {item.text}
                </Text>
              </Box>
            )
          }

          return null;
        })
      }
    </Flex>
  )
};

const WalletsWidget = () => {
  const { connection: { accountId, disconnectWallet } } = useContext(GlobalContext);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const flexRef = useRef(null);

  useOutsideClick({
    ref: flexRef,
    handler: onClose
  });

  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <Flex
          ref={flexRef}
          onClick={isOpen ? onClose : onOpen}
          gap={2} w={"fit-content"} alignItems="center" pl={7} pr={5} cursor="pointer">
          <GreenWalletIcon style={{ maxHeight: '24px', maxWidth: '24px' }} />
          <Text mt="2px" size="sm" variant="bodyBold" mr="auto">
            {accountId ? accountId : 'Connect Wallet'}
          </Text>
          <ArrowDownBlack style={styles.rotatingArrow(isOpen)} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent p={2} mt={6} w={200} borderRadius={16} _focusVisible={{ outline: 'none ' }}>
        <PopoverBody p={0}>
          {accountId ? (
            <Flex gap={4} flexDirection="column">
              {/* <Button variant="primary" borderRadius={6} onClick={connectWallet}>
                Switch wallet
              </Button> */}
              <Button
                variant="secondary"
                borderRadius={6}
                onClick={() => {
                  onClose();
                  disconnectWallet();
                }}
              >
                Disconnect
              </Button>
            </Flex>
          ) : <PopoverItems />}
        </PopoverBody>
      </PopoverContent>
    </Popover >
  );
};

export default WalletsWidget;
