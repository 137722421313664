import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { createContractTransaction } from '../../api/multisigAPI';
import { useToast } from '../../hooks/useToast';
import { GlobalContext } from '../../providers/Global';
import { EXAMPLE_ABI, ModalType } from '../../utils/constants';
import { AbiMethodTypes } from 'types/abi';
import AbiInput from 'components/ABI';
import { ModalBackBtn, MultiSigWarning } from './helpers';

const ContractInteractionModal = () => {
  const {
    modals: {
      [`${ModalType.ContractInteractionModal}`]: { open },
    },
    closeModal,
  } = useContext(GlobalContext);

  const [formatError, setFormatError] = useState(false);
  const [abi, setAbi] = useState('');
  const [isInvalid, setIsInvalid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [contractFunctions, setContractFunctions] = useState<any>({});
  const [selectedMethodInputs, setSelectedMethodInputs] = useState<any>([]);
  const [contractFunctionNames, setContractFunctionNames] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [contractId, setContractId] = useState('');
  const { showToast } = useToast();
  const { walletId } = useParams();

  const validateInputs = useCallback(() => {
    let isInvalid = false;
    if (!contractId || selectedMethodInputs.length === 0) {
      isInvalid = true;
      setIsInvalid(true);

      return;
    }

    selectedMethodInputs.forEach((method: any) => {
      // We don't really want to leave boolean values out. False is valid to execute a contract with,
      // so we have to make sure such types does not remain '' or undefined, if the user do not explicitly
      // trigger the Switch, as it will mess up validation otherwise.
      if (method.type === AbiMethodTypes.Bool) {
        if (typeof method.value !== 'boolean') {
          method.value = false;
          isInvalid = false;
        }
      } else if (!method.value) {
        isInvalid = true;
      }
    });
    setIsInvalid(isInvalid);
  }, [contractId, selectedMethodInputs]);

  useEffect(() => {
    validateInputs();
  }, [contractId, selectedMethodInputs, validateInputs]);

  const handleCreate = async () => {
    try {
      setIsLoading(true);
      const contractInteractionData = {
        contractId,
        contractMethod: selectedMethod,
        params: selectedMethodInputs.map((method: any) => {
          return {
            type: method.type,
            value: method.value,
          };
        }),
      };
      await createContractTransaction(walletId || '', contractInteractionData);
      showToast('Transaction created', 'success', undefined, 'top-right');
      window.location.reload();
    } catch (err: unknown) {
      showToast('The selected method is not supported', 'error', undefined, 'top-right');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    closeModal(ModalType.ContractInteractionModal);
  };

  const handleDataChange = (index: number, value: any) => {
    const updatedData = [...selectedMethodInputs];
    updatedData[index].value = value;
    setSelectedMethodInputs(updatedData);
  };

  const mapAbiData = (abi: any[]) => {
    const functions: any = {};
    const functionNames: any = [];

    abi.forEach(obj => {
      if (
        obj.type &&
        obj.stateMutability &&
        obj.type === 'function' &&
        obj.stateMutability !== 'view'
      ) {
        functionNames.push(obj.name);
        functions[obj.name] = {
          inputs: obj.inputs,
        };
      }
    });

    setContractFunctions(functions);
    setContractFunctionNames(functionNames);
    setSelectedMethodInputs(functions[functionNames[0]].inputs);
    setSelectedMethod(functionNames[0]);
  };

  const handleInputChange = (inputValue: string) => {
    try {
      mapAbiData(JSON.parse(inputValue));
      setFormatError(false);
    } catch (e) {
      setFormatError(true);
      setContractFunctions({});
      setContractFunctionNames([]);
    }
  };

  return (
    <>
      <Modal onClose={handleClose} isOpen={open} isCentered>
        <ModalOverlay />
        <ModalContent minWidth="586px">
          <ModalHeader pt={6} px={6} pb={0}>
            <ModalBackBtn />
            <Flex alignItems="center" flexDirection="column">
              <Text mt={10} size="2xl" variant="bodyBold">
                Contract Interaction
              </Text>
            </Flex>
            <ModalCloseButton
              mt={2}
              mr={2}
              color="#565656"
              _hover={{ border: 'none' }}
              _focusVisible={{ outline: 'none' }}
            />
          </ModalHeader>
          <ModalBody pb={14} pt={4} px={20}>
            <Flex alignItems="center" flexDirection="column" gap={4}>
              <Box width="full">
                <Text mb={2}>Contract address</Text>
                <Input
                  h={12}
                  size="sm"
                  borderRadius={16}
                  value={contractId}
                  onChange={event => setContractId(event.target.value)}
                />
              </Box>
              <Box width="full" >
                <Flex justifyContent={'space-between'}>
                  <Text mb={2} width={'fit-content'} >Contract ABI</Text>
                  <Tooltip
                    bg={'primary'}
                    placement='right'
                    borderRadius={12}
                    fontSize={10}
                    label={
                      <pre>
                        <Text textAlign={'center'} p={2} m={2} borderTopRadius={12} bg={'secondary'} fontSize={10}>
                          EXAMPLE ABI
                        </Text>
                        <Text color={'black'} m={5} fontSize={10}>
                          {EXAMPLE_ABI}
                        </Text>
                      </pre>}
                    children={<InfoIcon cursor={'pointer'} />}
                  />
                </Flex>
                <Textarea
                  size="sm"
                  value={abi}
                  borderRadius={16}
                  isInvalid={formatError}
                  _focusVisible={{
                    borderColor: formatError ? 'red' : '#3182ce',
                    boxShadow: formatError ? '0 0 0 1px red' : '0 0 0 1px #3182ce',
                  }}
                  onChange={event => {
                    setAbi(event.target.value);
                    handleInputChange(event.target.value);
                  }}
                />
                {formatError && (
                  <Text color="red" size="sm" mt={1} ml={2}>
                    Invalid ABI data
                  </Text>
                )}
              </Box>
              {contractFunctionNames.length > 0 && (
                <>
                  <Box width="full" mt={2}>
                    <Text mb={4}>Contract Methods</Text>
                    <Select
                      h={12}
                      borderRadius={16}
                      onChange={e => {
                        setSelectedMethodInputs(
                          contractFunctions[e.target.selectedOptions[0].value].inputs,
                        );
                        setSelectedMethod(e.target.selectedOptions[0].value);
                      }}
                    >
                      {contractFunctionNames?.map((functionName: string, index: number) => {
                        return (
                          <option key={index} value={functionName}>
                            {functionName}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>
                  {selectedMethodInputs.length > 0 && (
                    <Fragment>
                      <Divider orientation="horizontal" mt={2} />
                      <Flex width="full" gap={3} flexDirection="column">
                        {selectedMethodInputs.map((input: any, index: number) => {
                          const key = `${input.type}-${index}`;
                          return <AbiInput key={key} abiProps={{
                            selectedMethodInputs,
                            input,
                            index,
                            setter: handleDataChange
                          }} />
                        })}
                      </Flex>
                    </Fragment>
                  )}
                </>
              )}
              <MultiSigWarning />
              <Button
                w={203}
                mt={5}
                onClick={handleCreate}
                variant="primary"
                isLoading={isLoading}
                isDisabled={isInvalid}
              >
                Create
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContractInteractionModal;
