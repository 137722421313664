import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from "@chakra-ui/react";
import { Button, Flex, Text, Box } from "@chakra-ui/react";
import { GlobalContext } from "providers/Global";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowDownBlack } from 'assets/arrow-down-black-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg';
import { ModalType, SCREEN_LG, SCREEN_SM } from "utils/constants";
import { ReactPortal } from 'hooks/usePortal';

interface IMobileMenuProps {
  pageName: string;
  backUrl?: string;
}

export const MobileMenu = (props: IMobileMenuProps) => {
  const { pageName, backUrl } = props;

  const refContent = useRef<HTMLDivElement | null>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);

  const [isSticky, setIsSticky] = useState(false);

  const isScreenSmall = useMediaQuery({ query: `(max-width: ${SCREEN_SM})` });
  const isScreenLarge = useMediaQuery({ query: `(max-width: ${SCREEN_LG})` });

  const { openModal } = useContext(GlobalContext);

  const handleScroll = useCallback(() => {
    if (!isScreenSmall) {
      return;
    }

    setIsSticky(!!(refContent.current?.scrollTop));
  }, [isScreenSmall]);

  useEffect(() => {
    const el = document.getElementById('content') as HTMLDivElement;

    if (el) {
      refContent.current = el as HTMLDivElement;
      refContent.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (refContent.current) {
        refContent.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <ReactPortal
      id="content"
      withHeader
      disablePortal={!isScreenSmall}
    >
      <Flex
        ref={refContainer}
        justifyContent="space-between"
        alignItems="center"
        bg={isSticky ? 'white' : ''}
        boxShadow={isSticky ? 'md' : 'none'}
        top={isScreenSmall ? 0 : 'auto'}
        position={isScreenSmall ? 'sticky' : 'unset'}
        transition="box-shadow 0.3s ease-in-out"
        mt={isScreenSmall ? 0 : 2}
        mb={isScreenSmall ? -2 : 2}
      >
        {
          isScreenLarge && !!backUrl?.length && (
            <ChakraLink
              as={RouterLink}
              to={backUrl}
              w={12}
              h={12}
              pl={isScreenSmall ? 3 : 0}
              ml={!isScreenSmall ? -3 : 0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              textDecoration="none !important"
            >
              <Box transform="rotate(90deg)">
                <ArrowDownBlack />
              </Box>
            </ChakraLink>
          )
        }
        <Text
          size={isScreenSmall ? 'md' : "2xl"}
          variant="bodyBold"
        >
          {pageName}
        </Text>

        {
          isScreenLarge
            ? (
              <Flex
                alignItems="center"
                cursor="pointer"
                pr={isScreenSmall ? 6 : 0}
                onClick={() => openModal(ModalType.CreateTransactionModal)}
              >
                <Box transform="rotate(90deg)">
                  <PlusIcon />
                </Box>
                TX
              </Flex>
            )
            : (
              <Button
                variant="primary"
                borderRadius={6}
                onClick={() => openModal(ModalType.CreateTransactionModal)}
              >
                New Transaction
              </Button>
            )
        }
      </Flex>
    </ReactPortal>
  )
}

MobileMenu.displayName = 'MobileMenu';
